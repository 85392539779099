import React from "react";

import { Header, Text, Flex } from "Atoms";
import { messageLevelColors } from "Constants";

type Props = {
    qoplaMessage: any;
};

export const MessagePreview: React.FC<Props> = ({ qoplaMessage }) => {
    return (
        <Flex
            flexDirection="column"
            textAlign="center"
            marginY={4}
            backgroundColor={messageLevelColors[qoplaMessage.level]}
            color={qoplaMessage.level === "WARNING" || qoplaMessage.level === "NONE" ? "black" : "white"}
            padding={4}
            height="auto"
        >
            <Header as="h3" margin={0}>
                {qoplaMessage.title}
            </Header>
            <Text fontSize="1.2rem">{qoplaMessage.text}</Text>
        </Flex>
    );
};
