import React, { useState, useEffect } from "react";
import { Field } from "formik-next";

import { isUmbrellaAdmin, isUmbrellaUser } from "Utils";
import { SelectInput, SelectOption } from "Molecules";
import { AsyncSearchSelect, searchDatabase } from "./AsyncSearchInput/AsyncSearchSelect";
import { GET_SHOPS_BY_SEARCH, GET_ALL_SHOPS_BY_COMPANY_IDS } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { useLanguage } from "Providers";
import { AuthenticatedUser, Shop } from "Types";

type Props = {
    loggedInUser: AuthenticatedUser;
    shopOptions: SelectOption[];
    isUmbrellaCompany: boolean;
    fieldName?: string;
    setFieldValue: (fieldName: string, value: any) => void;
    setFieldTouched: (field: string) => void;
};

export const AssociatedRestaurantSelect: React.FC<Props> = ({
    loggedInUser,
    isUmbrellaCompany,
    shopOptions,
    fieldName = "shopIds",
    setFieldValue,
    setFieldTouched
}) => {
    const { translate } = useLanguage();

    const [shopList, setShopList] = useState(shopOptions);

    const _isUmbrellaAdmin = isUmbrellaAdmin(loggedInUser.roles);
    const availableCompaniesForUser = _isUmbrellaAdmin
        ? [...new Set([...loggedInUser.umbrellaCompanyIds, loggedInUser.companyId])]
        : [loggedInUser.companyId];

    const { data: companyShopsData, loading: companyShopsLoading } = useMothershipQuery(GET_ALL_SHOPS_BY_COMPANY_IDS, {
        variables: { companyIds: [...availableCompaniesForUser] },
        skip: shopList.length > 0
    });

    useEffect(() => {
        if (!shopList.length && companyShopsData) {
            const shopOptions = companyShopsData.getAllShopsByCompanyIds.filter((shop: Shop) => {
                return isUmbrellaCompany ? !shop.umbrellaShop : shop.companyId === loggedInUser.companyId;
            });
            setShopList(
                shopOptions.map((shop: Shop) => ({
                    value: shop.id,
                    label: shop.name,
                    isDisabled: shop.umbrellaShop
                }))
            );
        }
    }, [companyShopsData]);

    const getLoadingOptions = async (inputValue: string): Promise<SelectOption[]> => {
        return await searchDatabase(GET_SHOPS_BY_SEARCH, {
            variables: { search: inputValue, companyIds: loggedInUser.umbrellaCompanyIds }
        })
            .then(({ data }: any) => {
                return data.getShopsFromSearch?.map((select: SelectOption) => {
                    return {
                        ...select,
                        label: select.isDisabled ? `${select.label} - (${translate("umbrella")})` : select.label
                    };
                });
            })
            .catch((_: any) => {
                return [];
            });
    };

    return (
        <>
            {_isUmbrellaAdmin ? (
                <Field
                    name={fieldName}
                    formLabel={translate("associatedRestaurants")}
                    placeholder={translate("associatedRestaurants")}
                    isMulti
                    options={shopList}
                    setFieldValue={setFieldValue}
                    onBlur={setFieldTouched}
                    as={SelectInput}
                />
            ) : (
                <Field
                    name={fieldName}
                    formLabel={translate("associatedRestaurants")}
                    placeHolder={translate("search")}
                    noOptionsMessage={() => translate("enterNameOfRestaurant")}
                    searchLoadingOptions={getLoadingOptions}
                    setFieldValue={setFieldValue}
                    onBlur={setFieldTouched}
                    isMulti
                    isMandatory
                    isDisabled={isUmbrellaUser(loggedInUser.roles)}
                    as={AsyncSearchSelect}
                />
            )}
        </>
    );
};
