import React from "react";
import copy from "copy-to-clipboard";
import { Form, Formik } from "formik-next";

import { Box, Button, Stack, Text } from "Atoms";
import { CREATE_USER_ACCOUNT_RESET_PASSWORD_LINK } from "GraphQLMutations";
import { useMothershipMutation } from "Hooks";
import { FormInput } from "Molecules";
import { useLanguage } from "Providers";

type Props = {};

export const GetUserAccountPasswordResetLink: React.FC<Props> = ({}) => {
    const { translate } = useLanguage();

    const [createUserAccountResetPasswordLink, { data, called }] = useMothershipMutation(
        CREATE_USER_ACCOUNT_RESET_PASSWORD_LINK
    );

    const handleOnSubmit = async (values: { email: string }) => {
        try {
            createUserAccountResetPasswordLink({ variables: { email: values.email.trim() } });
        } catch (error) {
            console.log("Error when getting password reset for user account link in super admin", error);
        }
    };

    const onCopyLink = (link: string) => {
        copy(link);
    };

    const resetPasswordLink: null | string = data?.createUserAccountResetPasswordLink ?? null;

    return (
        <Box>
            <Formik initialValues={{ email: "" }} onSubmit={values => handleOnSubmit(values)}>
                {({ isSubmitting }) => (
                    <Form>
                        <Stack isInline align="center">
                            <FormInput
                                name="email"
                                formLabel={translate("userAccountEmail")}
                                isMandatory
                                fullWidth
                                wrapperProps={{ width: "full", mr: 3 }}
                            />
                            <Button type="submit" themeColor="green" isLoading={isSubmitting}>
                                {translate("fetch")}
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
            {called && (
                <Stack isInline stretch={3} align="center">
                    <Text as="span">{resetPasswordLink || translate("noLinkFound")}</Text>
                    {!!resetPasswordLink && (
                        <Button onClick={() => onCopyLink(resetPasswordLink)}>{translate("copy")}</Button>
                    )}
                </Stack>
            )}
        </Box>
    );
};
