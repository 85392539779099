import React from "react";
import { ResponsivePie } from "@nivo/pie";
import ReactTable from "react-table";

import { Header, Text, Box, Spinner, Flex } from "Atoms";
import { filterCaseInsensitiveReactTable } from "TempUtils";
import { swedishReactTableProperties } from "../../../utils/TextFormat";
import { frontendApplications } from "Constants";
import { formatDateToLocal } from "Utils";
import { colors } from "../../../../theme/colors";
import { GET_SHOP_CUSTOMER_DETAILS } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { useAccordionItemContext } from "Organisms";

const pieColors = [colors.green["500"], colors.teal["300"], colors.green["300"], colors.blue["300"]];

const pieDefs = [
    {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        size: 4,
        padding: 1,
        stagger: true
    },
    {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        rotation: -45,
        lineWidth: 6,
        spacing: 10
    }
];

type ShopCustomerDetailsType = {
    shopName: string;
    shopId: string;
    ip: string;
    lastSeenVersion: string;
    lastUpdate: string;
    frontendApplication: string;
};

export const RestaurantsIp: React.FC = () => {
    const { isExpanded } = useAccordionItemContext();
    const { data, loading: shopCustomerDetailsLoading } = useMothershipQuery(GET_SHOP_CUSTOMER_DETAILS, {
        fetchPolicy: "network-only",
        skip: !isExpanded
    });

    if (shopCustomerDetailsLoading || !data) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const shopCustomerDetails: ShopCustomerDetailsType[] = data?.getShopCustomerDetails;

    const pieData = Object.keys(frontendApplications).map((app: string, idx: number) => {
        const numberOfShops = shopCustomerDetails.reduce(
            (prevNumber: number, currVal: any) => (currVal.frontendApplication === app ? prevNumber + 1 : prevNumber),
            0
        );
        return {
            id: app,
            label: frontendApplications[app],
            value: numberOfShops,
            color: pieColors[idx]
        };
    });

    return (
        <>
            <Header as="h5" size="sm" fontWeight="normal">
                De senaste registrerade IP adresserna och versioner för orderskärmarna
            </Header>
            <Box height="250px" width="100%">
                <ResponsivePie
                    data={pieData}
                    margin={{ top: 20, right: 0, bottom: 30, left: 0 }}
                    startAngle={-90}
                    sortByValue={true}
                    activeOuterRadiusOffset={8}
                    innerRadius={0.45}
                    borderWidth={1}
                    cornerRadius={3}
                    colors={{ datum: "data.color" }}
                    borderColor={{ from: "data.color", modifiers: [["darker", 0.2]] }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    theme={{
                        fontSize: 14
                    }}
                    arcLabelsTextColor={{
                        from: "color",
                        modifiers: [["darker", 2]]
                    }}
                    arcLinkLabel="label"
                    defs={pieDefs}
                    fill={[
                        {
                            match: {
                                id: "FRONTY"
                            },
                            id: "lines"
                        },
                        {
                            match: {
                                id: "IOS_APP"
                            },
                            id: "dots"
                        }
                    ]}
                    tooltip={item => {
                        return (
                            <Box bg={"#EDF2F7C4"} borderRadius={4} p={2}>
                                {item.datum.label}
                                {": "} {item.datum.value}
                            </Box>
                        );
                    }}
                />
            </Box>
            <pre>
                <code
                    style={{
                        backgroundColor: "#eee",
                        border: "1px solid #999",
                        display: "block",
                        padding: "2em",
                        whiteSpace: "pre-wrap"
                    }}
                >
                    {/** @ts-ignore */}
                    <ReactTable
                        data={shopCustomerDetails}
                        className="-striped -highlight"
                        filterable
                        defaultFilterMethod={filterCaseInsensitiveReactTable}
                        {...swedishReactTableProperties}
                        columns={[
                            { Header: "Namn", accessor: "shopName" },
                            {
                                Header: "IP",
                                accessor: "ip"
                            },
                            {
                                Header: "Senaste version",
                                accessor: "lastSeenVersion"
                            },
                            {
                                Header: "Frontend applikation",
                                accessor: "frontendApplication",
                                Cell: row => {
                                    return <Text>{frontendApplications[row.value]}</Text>;
                                }
                            },
                            {
                                Header: "Senast uppdaterad",
                                accessor: "lastUpdate",
                                Cell: row => formatDateToLocal(row.value)
                            }
                        ]}
                    />
                </code>
            </pre>
        </>
    );
};
