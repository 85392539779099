import React, { useState } from "react";
import moment from "moment";

import { Header, Flex, Button, Input } from "Atoms";
import { RUN_MISSING_REPORTS, RUN_MISSING_Z_REPORTS } from "GraphQLMutations";
import { useMothershipMutation } from "Hooks";
import { confirmNotification } from "TempUtils";
import { MessageNotification } from "../../../shared";

export const MissedReports: React.FC = () => {
    const [missingReportsDate, setMissingReportsDate] = useState(moment().subtract(1, "d").format("YYYY-MM-DD HH:mm"));
    const [shouldRenderFeedback, setShouldRenderFeedback] = useState(false);

    const [runMissingReport] = useMothershipMutation(RUN_MISSING_REPORTS);
    const [runMissingZReport] = useMothershipMutation(RUN_MISSING_Z_REPORTS);

    return (
        <>
            <Header as="h4" size="md" margin="0">
                Saknade rapporter (ALLT)
            </Header>
            <Header as="h5" size="sm" fontWeight="normal">
                Denna knapp kan generera missade z-rapporter och allt som hör till (SIE, Fortnox etc). Välj datum nedan
                så kommer den generera rapporter för alla shoppar som saknar rapporter INNAN valda datumet. Kommer sätta
                slutdatum till nu.
            </Header>
            <Flex flexDirection={["column", "row"]} justifyContent="space-evenly">
                <Input
                    type="text"
                    value={missingReportsDate}
                    onChange={(e: any) => setMissingReportsDate(e.target.value)}
                />
                <Button
                    m={2}
                    onClick={async () => {
                        setShouldRenderFeedback(false);
                        const { value } = await confirmNotification(
                            "Säkert?",
                            "Är du helt säker på att du ska köra denna. Dubbelkolla först med Peter eller Sahir.",
                            "Ja, de har godkänt"
                        );
                        if (value) {
                            const result = await runMissingReport({
                                variables: { date: moment(missingReportsDate) }
                            });

                            if (result) {
                                setShouldRenderFeedback(true);
                            }
                        }
                    }}
                >
                    Återskapa
                </Button>
            </Flex>
            <Header as="h4" size="md" margin="0">
                Saknade rapporter (ENDAST Z)
            </Header>
            <Header as="h5" size="sm" fontWeight="normal">
                Denna knapp genererar ENDAST missade z-rapporter. Välj datum nedan så kommer den generera z-rapporterna
                för alla shoppar som saknar rapporter INNAN valda datumet. Kommer sätta slutdatum till start + 24h.
            </Header>
            <Flex flexDirection={["column", "row"]} justifyContent="space-evenly">
                <Input
                    type="text"
                    value={missingReportsDate}
                    onChange={(e: any) => setMissingReportsDate(e.target.value)}
                />
                <Button
                    m={2}
                    onClick={async () => {
                        setShouldRenderFeedback(false);
                        const { value } = await confirmNotification(
                            "Säkert?",
                            "Är du helt säker på att du ska köra denna. Dubbelkolla först med Peter eller Sahir.",
                            "Ja, de har godkänt"
                        );
                        if (value) {
                            const result = await runMissingZReport({
                                variables: { date: moment(missingReportsDate) }
                            });

                            if (result) {
                                setShouldRenderFeedback(true);
                            }
                        }
                    }}
                >
                    Återskapa
                </Button>
            </Flex>

            {shouldRenderFeedback && <MessageNotification content={"Mutation Successful!"} />}
        </>
    );
};
