import React from "react";
import { flatMap } from "lodash";

import { Spinner, Flex } from "Atoms";
import { GET_COMPANY_AND_RESTAURANT_EMAILS } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { CSVDownloadButton } from "../../report-dashboards/components/CSVDownloadButton";
import { useAccordionItemContext } from "Organisms";

export const RestaurantsEmails: React.FC = () => {
    const { isExpanded } = useAccordionItemContext();

    const { data: companyAndRestaurantEmails, loading: companyAndRestaurantEmailsLoading } = useMothershipQuery(
        GET_COMPANY_AND_RESTAURANT_EMAILS,
        {
            fetchPolicy: "network-only",
            skip: !isExpanded
        }
    );

    if (companyAndRestaurantEmailsLoading || !companyAndRestaurantEmails) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const csvHeaders = [{ label: "E-post", key: "email" }];
    const csvData = () => {
        const emails = companyAndRestaurantEmails.getCompanyAndRestaurantEmails.map((email: string) => ({
            email: email
        }));
        return flatMap(emails, tableRow => ({
            ...tableRow,
            email: tableRow && tableRow.email
        }));
    };
    return <CSVDownloadButton filename={`Qopla-shop-emails.csv`} csvHeaders={csvHeaders} csvData={csvData()} />;
};
