import React from "react";
import { Formik, Field } from "formik-next";
import * as Yup from "yup";
import moment from "moment";

import { Button, Header } from "Atoms";
import { FormInput } from "Molecules";
import { useMothershipMutation } from "Hooks";
import { START_XML_EXPORT } from "GraphQLMutations";
import { errorNotification, successNotification } from "TempUtils";

type ExportParams = {
    companyId: string;
    startDate: string;
    endDate: string;
};

const getSchema = (): object => {
    return Yup.object().shape({
        companyId: Yup.string().required("Måste fyllas i"),
        startDate: Yup.string().required("Måste fyllas i"),
        endDate: Yup.string().required("Måste fyllas i")
    });
};

const timeFormat = "YYYY-MM-DD HH";

export const ExportXMLData: React.FC<{}> = ({}) => {
    const [startExport] = useMothershipMutation(START_XML_EXPORT);
    const initialData = {
        companyId: "",
        startDate: moment().subtract(1, "M").format(timeFormat),
        endDate: moment().format(timeFormat)
    };

    const initiateExport = async (values: ExportParams) => {
        const { data } = await startExport({
            variables: {
                companyId: values.companyId,
                startDate: moment(values.startDate, timeFormat),
                endDate: moment(values.endDate, timeFormat)
            }
        });
        if (data.exportDataByDate) {
            successNotification("Export slutförd", 1400);
        } else {
            errorNotification("Export misslyckades", "Något gick snett, gräv i loggarna.");
        }
    };

    return (
        <>
            <Header as="h4" size="md" margin="0">
                Exportera Order & Abonnemangsdata
            </Header>
            <Header as="h5" size="sm" fontWeight="normal">
                Knappa in företags id och start och slutdatum för att starta export. Exporten sker till SFTP. Gäller
                endast order och abonnemangsdata.
            </Header>
            <Formik<ExportParams> initialValues={initialData} onSubmit={initiateExport} validationSchema={getSchema()}>
                {({ handleSubmit, isValid, dirty, isSubmitting }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Field name="companyId" formLabel="Företags Id" isMandatory fullWidth as={FormInput} />
                            <Field
                                name="startDate"
                                formLabel="Från (YYYY-MM-DD HH)"
                                isMandatory
                                fullWidth
                                as={FormInput}
                            />
                            <Field
                                name="endDate"
                                formLabel="Till (YYYY-MM-DD HH)"
                                isMandatory
                                fullWidth
                                as={FormInput}
                            />
                            <Button
                                type="submit"
                                themeColor="green"
                                disabled={!(isValid && dirty) || isSubmitting}
                                fullWidth
                            >
                                Exportera
                            </Button>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};
