import React from "react";
import { GET_SWISH_PAYMENT_STATUS } from "GraphQLQueries";
import { useForm } from "react-hook-form";

import { useMothershipLazyQuery } from "Hooks";
import { Button, Header, RHFormInput, Box, ReactJson } from "Atoms";
import { useLanguage } from "Providers";

type SwishStatusForm = {
    orderId: string;
};

const SwishPaymentStatus: React.FC = () => {
    const { translate } = useLanguage();

    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting }
    } = useForm<SwishStatusForm>({
        defaultValues: { orderId: "" }
    });

    const [getOrderInfo, { data, loading, error }] = useMothershipLazyQuery(GET_SWISH_PAYMENT_STATUS);

    const onSubmit = (formValues: SwishStatusForm) => {
        getOrderInfo({ variables: { orderId: formValues.orderId } });
    };

    return (
        <>
            <Header as="h5" size="sm" fontWeight="normal">
                {translate("checkSwishStatusInputOrderId")}
            </Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <RHFormInput
                    control={control}
                    formLabel="Order Id"
                    placeholder="Order Id"
                    name="orderId"
                    isFullWidth
                    isMandatory
                ></RHFormInput>
                <Button type="submit" themeColor="green" disabled={!isDirty} isLoading={isSubmitting} fullWidth>
                    {translate("fetch")}
                </Button>
            </form>

            {data && !loading && (
                <Box p={10}>
                    <Header as="h3" size="md">
                        {translate("result")}:
                    </Header>
                    <ReactJson src={data?.getSwishPaymentStatus} />
                </Box>
            )}

            {error && (
                <Box p={10}>
                    <Header as="h3" size="md" color="red.600">
                        {translate("couldNotFindSwishWebOrder")}
                    </Header>
                </Box>
            )}
        </>
    );
};

export default SwishPaymentStatus;
