import React from "react";

import {
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Drawer,
    DrawerOverlay,
    DrawerActions
} from "Organisms";
import { IDrawerComponentContext, useLanguage } from "Providers";
import { Box } from "Atoms";
import { SIEAccount, AlreadySelectedValuesType } from "../AccountingAccounts";
import { SIEAccountForm } from "../SIEAccountForm";

type CategoryIdName = {
    id: string;
    name: string;
};

type Props = {
    sieAccount: SIEAccount;
    sieAccountType: string;
    alreadySelectedValues: AlreadySelectedValuesType;
    alreadyUsedAccountNumbers: number[];
    isEdit: boolean;
    bundleAndRefProducts: any[];
    refCategories: CategoryIdName[];
    vatRates: number[];
    thirdPartyServices: string[];
    upsertAccount: (values: SIEAccount, accountType: string) => void;
};

export const SIEAccountDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: {
        sieAccount,
        sieAccountType,
        alreadySelectedValues,
        bundleAndRefProducts,
        refCategories,
        vatRates,
        thirdPartyServices,
        alreadyUsedAccountNumbers,
        upsertAccount
    },
    onCloseDrawer
}) => {
    const { translate } = useLanguage();

    const isEdit = !!sieAccount?.id;
    const sideBarHeader = isEdit ? translate("updateAccount") : translate("addNewAccount");

    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center">
                    <Box pt={6} pb={4}>
                        {sideBarHeader}
                    </Box>
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    <SIEAccountForm
                        alreadySelectedValues={alreadySelectedValues}
                        accountType={sieAccountType}
                        isEdit={isEdit}
                        sieAccount={sieAccount}
                        bundleAndRefProducts={bundleAndRefProducts}
                        refCategories={refCategories}
                        vatRates={vatRates}
                        thirdPartyServices={thirdPartyServices}
                        alreadyUsedAccountNumbers={alreadyUsedAccountNumbers}
                        onClose={onCloseDrawer}
                        upsertAccount={upsertAccount}
                    />
                </DrawerBody>
                <DrawerActions></DrawerActions>
            </DrawerContent>
        </Drawer>
    );
};
