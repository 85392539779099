import React from "react";
import styled, { keyframes } from "styled-components";

import { BaseBoxProps, Box } from "../../../../../atoms/Box/Box";

const opacity = keyframes`
    0% { will-change: opacity; opacity: 0};
    100% { will-change: opacity; opacity: 1};
`;

const BoxAnimation = styled(Box)`
    animation: 400ms ${opacity} ease-in;
`;

export const FadeInBox: React.FC<BaseBoxProps> = ({ ...props }) => <BoxAnimation {...props} />;
