import React from "react";

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "Organisms";
import { useLanguage } from "Providers";
import { GetCompanyUserPasswordResetLink, GetUserAccountPasswordResetLink } from "./";

type Props = {};

export const SuperAdminGetResetPasswordLink: React.FC<Props> = ({}) => {
    const { translate } = useLanguage();

    return (
        <Tabs>
            <TabList>
                <Tab>{translate("userAccount")}</Tab>
                <Tab>{translate("companyUser")}</Tab>
            </TabList>
            <TabPanels pt={4}>
                <TabPanel>
                    <GetUserAccountPasswordResetLink />
                </TabPanel>
                <TabPanel>
                    <GetCompanyUserPasswordResetLink />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};
