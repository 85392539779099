import React, { useState, useEffect } from "react";

import { Text, Box, Flex, Button, Checkbox, PseudoBox } from "Atoms";
import { GET_GLOBAL_QOPLA_OBJECT, GET_QOPLA_MESSAGE_BY_ID } from "GraphQLQueries";
import { SET_GLOBAL_QOPLA_OBJECT, SET_QOPLA_MESSAGE } from "GraphQLMutations";
import { useMothershipQuery, useMothershipLazyQuery, useMothershipMutation } from "Hooks";
import { MessagePreview } from "./";
import { successNotification } from "TempUtils";
import { useModal } from "Providers";
import {
    messageInitialState,
    ShopQoplaMessageType,
    MESSAGE_TYPE,
    QoplaMessageType,
    GlobalQoplaObjectType
} from "../SuperAdmin";
import { modalNames } from "Constants";
import { useAccordionItemContext } from "Organisms";

type Props = {
    globalQoplaMessage: QoplaMessageType;
    setGlobalQoplaMessage: (globalMessage: ShopQoplaMessageType) => void;
    setShopQoplaMessage: (message: ShopQoplaMessageType) => void;
};

export const GlobalQoplaMessage: React.FC<Props> = ({
    globalQoplaMessage,
    setGlobalQoplaMessage,
    setShopQoplaMessage
}) => {
    const { openModal } = useModal();
    const { isExpanded } = useAccordionItemContext();

    const [globalQoplaObject, setGlobalQoplaObject] = useState<GlobalQoplaObjectType>({
        id: null,
        closeAllExpress: false,
        allShopsClosed: {
            enabled: false
        },
        systemMessage: {
            enabled: false,
            messageId: ""
        }
    });

    const { data: qoplaGlobalObjectData, loading: qoplaGlobalObjectLoading } = useMothershipQuery(
        GET_GLOBAL_QOPLA_OBJECT,
        {
            fetchPolicy: "network-only",
            skip: !isExpanded
        }
    );

    const [getQoplaMessageByIdQuery, { loading: getQoplaMessageByIdLoading, data: getQoplaMessageByIdData }] =
        useMothershipLazyQuery(GET_QOPLA_MESSAGE_BY_ID, {
            fetchPolicy: "network-only"
        });

    const [setGlobalQoplaObjectMutation] = useMothershipMutation(SET_GLOBAL_QOPLA_OBJECT);
    const [setQoplaMessageMutation] = useMothershipMutation(SET_QOPLA_MESSAGE);

    useEffect(() => {
        if (qoplaGlobalObjectLoading || !qoplaGlobalObjectData || !qoplaGlobalObjectData.getGlobalQoplaObject) return;
        setGlobalQoplaObject(qoplaGlobalObjectData.getGlobalQoplaObject);
        getQoplaMessageByIdQuery({
            variables: {
                messageId: qoplaGlobalObjectData.getGlobalQoplaObject.systemMessage.messageId
            }
        });
    }, [qoplaGlobalObjectLoading]);

    useEffect(() => {
        if (getQoplaMessageByIdLoading || !getQoplaMessageByIdData || !getQoplaMessageByIdData.getQoplaMessageById)
            return;
        setGlobalQoplaMessage(getQoplaMessageByIdData.getQoplaMessageById);
    }, [getQoplaMessageByIdLoading]);

    return (
        <>
            <Flex flexDirection="column">
                <Checkbox
                    checked={globalQoplaObject.allShopsClosed.enabled}
                    onChange={() =>
                        setGlobalQoplaObject(currState => ({
                            ...currState,
                            allShopsClosed: {
                                enabled: !currState.allShopsClosed.enabled
                            }
                        }))
                    }
                >
                    Stäng alla restauranger
                </Checkbox>
                <Checkbox
                    checked={globalQoplaObject.closeAllExpress}
                    onChange={() =>
                        setGlobalQoplaObject(currState => ({
                            ...currState,
                            closeAllExpress: !currState.closeAllExpress
                        }))
                    }
                >
                    Stäng alla Expresskassor (kort)
                </Checkbox>
                <Checkbox
                    checked={globalQoplaObject.systemMessage.enabled}
                    onChange={() =>
                        setGlobalQoplaObject(currState => ({
                            ...currState,
                            systemMessage: {
                                ...currState.systemMessage,
                                enabled: !currState.systemMessage.enabled
                            }
                        }))
                    }
                >
                    Systemmeddelande
                </Checkbox>
            </Flex>
            <Text margin={0}>(OBS! Detta meddelande kommer att visas på alla restaurangsidor)</Text>
            <Flex flexDirection="column" position="relative">
                {!globalQoplaObject.systemMessage.enabled && (
                    <Box
                        position="absolute"
                        backgroundColor="rgba(255, 255, 255, 0.8)"
                        width="100%"
                        height="100%"
                        cursor="not-allowed"
                    />
                )}
                <PseudoBox
                    _hover={{ filter: "brightness(95%)", cursor: "pointer" }}
                    onClick={() => {
                        openModal(modalNames.QOPLA_MESSAGE_MODAL, {
                            messageType: MESSAGE_TYPE.GLOBAL,
                            globalQoplaMessage,
                            setGlobalQoplaMessage,
                            setShopQoplaMessage
                        });
                    }}
                >
                    <MessagePreview qoplaMessage={globalQoplaMessage} />
                </PseudoBox>
            </Flex>
            <Flex flexDirection="column">
                <Button
                    themeColor="blue"
                    style={{ marginTop: "1rem" }}
                    onClick={() => {
                        openModal(modalNames.QOPLA_MESSAGE_MODAL, {
                            messageType: MESSAGE_TYPE.GLOBAL,
                            messageInitialState,
                            setGlobalQoplaMessage,
                            setShopQoplaMessage
                        });
                    }}
                >
                    Skriv nytt meddelande
                </Button>
                <Button
                    themeColor="green"
                    style={{ marginTop: "1rem" }}
                    onClick={async () => {
                        const { shouldSendAsEmail, shouldShowOnOrderDisplay, ...shopMessageInput } = globalQoplaMessage;
                        const { data: setQoplaMessageData } = await setQoplaMessageMutation({
                            variables: {
                                shopMessage: {
                                    ...shopMessageInput,
                                    id: undefined,
                                    email: false,
                                    orderDisplay: false
                                },
                                enabled: globalQoplaObject.systemMessage.enabled
                            }
                        });
                        const { data: setGlobalQoplaObjectData } = await setGlobalQoplaObjectMutation({
                            variables: {
                                qopla: {
                                    ...globalQoplaObject,
                                    systemMessage: {
                                        ...globalQoplaObject.systemMessage,
                                        messageId: setQoplaMessageData.setQoplaMessage.id
                                    }
                                }
                            }
                        });
                        setGlobalQoplaObject((currState: any) => ({
                            ...currState,
                            ...setGlobalQoplaObjectData.setGlobalQoplaObject
                        }));
                        successNotification("Qoplas globala inställningar har uppdaterats", 1400);
                    }}
                >
                    Använd inställningar
                </Button>
            </Flex>
        </>
    );
};
