import React, { useState, useEffect } from "react";
import { Control, useWatch, UseFormSetValue } from "react-hook-form";
import { groupBy } from "lodash";

import {
    Flex,
    Text,
    NewDivider as Divider,
    Box,
    RHCheckBoxInput,
    RHFormInput,
    RHSelectInput,
    RHTextAreaInput,
    RHGroupSelectInput,
    SelectOption,
    GroupSelectedOption
} from "Atoms";
import { GET_SUPER_ADMIN_COMPANIES, GET_ALL_SHOPS_BY_COMPANY_IDS, GET_SUPER_ADMIN_POS_SHOPS } from "GraphQLQueries";
import { MessagePreview } from "./";
import { useLanguage } from "Providers";
import { useMothershipQuery, useMothershipLazyQuery } from "Hooks";
import { Shop } from "Types";
import { MESSAGE_TYPE, ShopQoplaMessageType, MESSAGE_LEVELS } from "../SuperAdmin";
import { SuperAdminCompany } from "../../company/components/SuperAdminCompaniesTable";

type Props = {
    messageFormState: ShopQoplaMessageType;
    messageLevels: {
        label: string;
        value: keyof typeof MESSAGE_LEVELS | string;
    }[];
    messageType: keyof typeof MESSAGE_TYPE | string;
    control: Control<ShopQoplaMessageType>;
    setValue: UseFormSetValue<ShopQoplaMessageType>;
};

export const MessageInput: React.FC<Props> = ({ messageFormState, messageLevels, messageType, control, setValue }) => {
    const { translate } = useLanguage();

    const { data: companiesData } = useMothershipQuery(GET_SUPER_ADMIN_COMPANIES, {
        fetchPolicy: "network-only",
        skip: messageType === MESSAGE_TYPE.GLOBAL
    });

    const { data: posShopsData } = useMothershipQuery(GET_SUPER_ADMIN_POS_SHOPS, {
        fetchPolicy: "network-only",
        skip: messageType === MESSAGE_TYPE.GLOBAL
    });

    const [loadCompaniesShops, { data: allShopsData }] = useMothershipLazyQuery(GET_ALL_SHOPS_BY_COMPANY_IDS, {
        fetchPolicy: "network-only"
    });

    const [shopSelectOptions, setShopSelectOptions] = useState<GroupSelectedOption[]>([]);

    useEffect(() => {
        if (companiesData && companiesData?.getSuperAdminCompanyList) {
            const companyList = companiesData?.getSuperAdminCompanyList;
            loadCompaniesShops({
                variables: {
                    companyIds: companyList.map((superAdminCompany: SuperAdminCompany) => superAdminCompany.company.id)
                }
            });
        }
    }, [companiesData]);

    useEffect(() => {
        if (companiesData && allShopsData && allShopsData?.getAllShopsByCompanyIds) {
            const shops = allShopsData?.getAllShopsByCompanyIds;
            const shopsGroupedByCopanies = groupBy(shops, "companyId");
            const companiesWithShops = companiesData.getSuperAdminCompanyList
                .filter(({ shopNames }: SuperAdminCompany) => shopNames.length > 0)
                .map((superAdminCompany: SuperAdminCompany) => {
                    const { company } = superAdminCompany;
                    const shops = shopsGroupedByCopanies[company?.id];

                    if (!company || !shops) {
                        return null;
                    }
                    return {
                        label: company.name,
                        options: shops.map((shop: Shop) => ({
                            label: shop.name,
                            value: shop.id
                        }))
                    };
                })
                .filter(Boolean);
            setShopSelectOptions(companiesWithShops);
        }
    }, [allShopsData, companiesData]);

    const selectAllShops = useWatch({ control, name: "selectAllShops" });
    const selectAllShopsWithPOS = useWatch({ control, name: "selectAllShopsWithPOSenabled" });

    useEffect(() => {
        if (selectAllShopsWithPOS && posShopsData?.getShopsWithPOS?.length != messageFormState.shopIds?.length) {
            setValue("selectAllShopsWithPOSenabled", false);
        }
        if (selectAllShops && allShopsData?.getAllShopsByCompanyIds?.length != messageFormState.shopIds?.length) {
            setValue("selectAllShops", false);
        }
    }, [messageFormState.shopIds]);

    const handleSelectAllShops = () => {
        const newSelectAllShops = !selectAllShops;
        setValue("selectAllShops", newSelectAllShops);
        if (selectAllShopsWithPOS) {
            setValue("selectAllShopsWithPOSenabled", false);
        }
        const allShopsOptions = newSelectAllShops
            ? shopSelectOptions?.flatMap((item: GroupSelectedOption) =>
                  item?.options?.map((option: SelectOption) => option?.value)
              ) || []
            : [];

        setValue("shopIds", allShopsOptions as string[]);
    };

    const handleSelectAllShopsWithPOS = () => {
        const newSelectAllShopsWithPOS = !selectAllShopsWithPOS;
        setValue("selectAllShopsWithPOSenabled", newSelectAllShopsWithPOS);
        if (selectAllShops) {
            setValue("selectAllShops", false);
        }
        const allShopsOptions = newSelectAllShopsWithPOS
            ? posShopsData?.getShopsWithPOS.map(({ id }: Shop) => id) || []
            : [];
        setValue("shopIds", allShopsOptions as string[]);
    };

    return (
        <Flex flexDirection="column" overflowX={"hidden"} p={1}>
            <RHFormInput
                control={control}
                name="title"
                placeholder={translate("title")}
                minWidth="100%"
                marginBottom={2}
            />
            <RHTextAreaInput
                control={control}
                marginTop={2}
                name="text"
                placeholder={translate("message")}
                minHeight={40}
                minWidth="100%"
                resize="none"
            />
            <Box my={4}>
                <RHSelectInput
                    control={control}
                    name="level"
                    placeholder={translate("typeOfSystemMessage")}
                    options={messageLevels}
                />
            </Box>
            {messageType === MESSAGE_TYPE.SHOP && (
                <>
                    <Flex alignItems={"flex-start"} direction={"column"}>
                        <RHCheckBoxInput
                            control={control}
                            name="selectAllShops"
                            formLabel={translate("selectAllRestarunts")}
                            textAlign="left"
                            onChange={handleSelectAllShops}
                            mr={2}
                            wrapperProps={{ marginBottom: 0 }}
                        />
                        <RHCheckBoxInput
                            control={control}
                            name="selectAllShopsWithPOSenabled"
                            formLabel={translate("selectAllShopsWithPOSenabled")}
                            textAlign="left"
                            mr={2}
                            wrapperProps={{ marginBottom: 0 }}
                            onChange={handleSelectAllShopsWithPOS}
                        />
                    </Flex>
                    <RHGroupSelectInput
                        control={control}
                        name="shopIds"
                        options={shopSelectOptions}
                        placeholder={translate("select")}
                        isMulti
                        wrapperProps={{
                            maxHeight: "250px",
                            minHeight: "45px",
                            overflowY: "auto",
                            padding: "1px"
                        }}
                    />
                    <Flex alignItems={"flex-start"} direction={"column"}>
                        <RHCheckBoxInput
                            control={control}
                            name="shouldSendAsEmail"
                            formLabel={translate("sendMessageAsEmail")}
                            textAlign="left"
                            mr={2}
                            wrapperProps={{ marginBottom: 0 }}
                        />
                        <RHCheckBoxInput
                            control={control}
                            name="shouldShowOnOrderDisplay"
                            formLabel={translate("showMessageOnOrderDisplay")}
                            textAlign="left"
                            mr={2}
                            wrapperProps={{ marginBottom: 0 }}
                        />
                    </Flex>
                </>
            )}
            <Flex alignItems="center" marginTop={4} overflowX={"hidden"}>
                <Divider marginX={12} width="50%" />
                <Text margin={0}>{translate("preview")}</Text>
                <Divider marginX={12} width="50%" />
            </Flex>
            <MessagePreview qoplaMessage={messageFormState} />
        </Flex>
    );
};
