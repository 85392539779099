import React from "react";

import { Flex } from "Atoms";
import { useLanguage } from "Providers";
import { formatSwedishFinancialNumbers } from "PriceUtils";
import { RefundOrCancel } from "../types";
import { StandardText } from "./StandardText";
import { getVatAmount } from "PriceUtils";

export const RefundSummary: React.FC<{ values: RefundOrCancel }> = ({ values }) => {
    const { translate, translateWithArgument } = useLanguage();

    const exceededMaxRefundAmount = values.amount > values.maxRefundAmount;
    const textColor = exceededMaxRefundAmount ? "red.500" : "gray.900";

    
    const vatAmount = getVatAmount(values.vatRate ?? 12, values.amount as number);
    const calculatedVat = exceededMaxRefundAmount ? 0 : formatSwedishFinancialNumbers(vatAmount);
    const refundAmount = exceededMaxRefundAmount ? 0 : formatSwedishFinancialNumbers(values.amount as number);

    return (
        <Flex direction="column" height="fit-content" mb={4}>
            <Flex width="50%" justifyContent="space-between">
                <StandardText ml={0} color={textColor}>{`${translate("total")} :`}</StandardText>
                <StandardText ml={0} color={textColor}>
                    {refundAmount}
                </StandardText>
            </Flex>
            <Flex width="50%" justifyContent="space-between" mt={1}>
                <StandardText ml={0} color={textColor}>{`${translateWithArgument(
                    "vatRate",
                    values.vatRate
                )} :`}</StandardText>
                <StandardText ml={0} color={textColor}>
                    {calculatedVat}
                </StandardText>
            </Flex>
        </Flex>
    );
};
