import { useState } from "react";

const createPromise = <T>() => {
    let resolver: any, reject: any;
    return [
        new Promise<T>((resolve, reject) => {
            resolver = resolve;
            reject = reject;
        }),
        resolver,
        reject
    ];
};

type HookReturnProps<T> = {
    onGetPromise: () => Promise<T>;
    onClick: (status: T) => Promise<void>;
    showConfirmation: boolean;
};

export const useExtendPromise = <T>(): HookReturnProps<T> => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [resolve, setResolve] = useState<{ resolver?: (value: T) => void | undefined }>({
        resolver: undefined
    });

    const onGetPromise = async () => {
        setShowConfirmation(true);
        const [promise, resolver] = await createPromise<T>();
        setResolve({ resolver: resolver });
        return promise;
    };

    const onClick = async (value: T) => {
        resolve?.resolver?.(value);
        setShowConfirmation(false);
    };

    return { onGetPromise, onClick, showConfirmation };
};
