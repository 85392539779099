import React from "react";

import { Header, Skeleton } from "Atoms";
import { GET_GROUP_SUB_BY_SUBID_AND_USERID } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { Subscription, SubscriptionStatus } from "Types";
import { FlexLabelAndValue } from "./FlexLabelAndValue";
import { Query, UserSubscriptionOverview } from "../types";
import { useLanguage } from "Providers";

type Props = {
    userSubscription: UserSubscriptionOverview;
    subscription?: Subscription;
};

export const GroupInformation: React.FC<Props> = ({ userSubscription, subscription }) => {
    const { translate } = useLanguage();
    const { data: groupSub, loading: loadingGroupSub } = useMothershipQuery<Query.FullGroupSubscription>(
        GET_GROUP_SUB_BY_SUBID_AND_USERID,
        {
            variables: {
                subscriptionId: subscription?.id,
                userSubscriptionId: userSubscription.id
            }
        }
    );
    const groupSubscription = groupSub?.getGroupSubscriptionBySubIdAndUserSubId;
    const isCancelled = userSubscription.status === SubscriptionStatus.CANCELLED;

    if (loadingGroupSub) {
        return (
            <>
                {[...Array(4)].map((_, index: number) => {
                    return <Skeleton height="1rem" width="100%" mb={3} key={index} />;
                })}
            </>
        );
    }

    if (isCancelled) {
        return (
            <>
                <Header size="sm">{translate("groupSubscription")}:</Header>
                <FlexLabelAndValue mt={4} label={translate("subscriptions")} value={userSubscription.customerEmail} />
                <FlexLabelAndValue mt={4} label={translate("status")} value="Avslutad" />
            </>
        );
    }

    if (!groupSubscription) {
        return (
            <>
                <Header size="sm">{`${translate("groupSubscription")}: ${translate("noInformation")}`}</Header>
            </>
        );
    }

    const { totalSeats = 0, userSubscriptions } = groupSubscription || {};
    const numberOfUsedPlaces = userSubscriptions?.length ?? 0;
    const placesLeft = totalSeats - numberOfUsedPlaces;

    return (
        <>
            <Header size="sm">{translate("groupSubscription")}:</Header>
            <FlexLabelAndValue
                mt={2}
                label={translate("companyName")}
                value={groupSubscription?.recipientContactInformation.companyName}
            />
            <FlexLabelAndValue
                mt={4}
                label={`${translate("quantity")} ${translate("subscriptions").toLowerCase()}`}
                value={groupSubscription?.totalSeats}
            />
            <FlexLabelAndValue mt={4} label={translate("placesLeft")} value={placesLeft} />
        </>
    );
};
