import React from "react";
import { useMutation } from "react-apollo";
import { useForm, useWatch } from "react-hook-form";

import { Button, Flex, Header } from "Atoms";
import { Modal, ModalHeader, ModalBody, ModalActions, ModalCloseBtn } from "Molecules";
import { SET_QOPLA_MESSAGE } from "GraphQLMutations";
import { successNotification } from "TempUtils";
import { MessageInput } from "./MessageInput";
import { MESSAGE_TYPE, MESSAGE_LEVELS, ShopQoplaMessageType } from "../SuperAdmin";
import { IModalContext, useLanguage } from "Providers";
import { modalNames } from "Constants";

type Props = {
    messageType: keyof typeof MESSAGE_TYPE | string;
    globalQoplaMessage: ShopQoplaMessageType | undefined;
    setGlobalQoplaMessage: (values: ShopQoplaMessageType) => void;
    setShopQoplaMessage: (values: ShopQoplaMessageType) => void;
};

export const QoplaMessageModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();

    const [setQoplaMessageMutation] = useMutation(SET_QOPLA_MESSAGE);

    const { globalQoplaMessage, messageType, setGlobalQoplaMessage, setShopQoplaMessage } = modalContent;

    let initialValues = { ...globalQoplaMessage };

    if (messageType === MESSAGE_TYPE.SHOP) {
        initialValues = {
            ...initialValues,
            shouldSendAsEmail: true,
            selectAllShops: false,
            selectAllShopsWithPOSenabled: false
        };
    }
    const { control, setValue, getValues } = useForm<ShopQoplaMessageType>({
        mode: "onBlur",
        defaultValues: initialValues
    });
    const values = getValues();

    const [text, title, level, shopIds, shouldSendAsEmail, shouldShowOnOrderDisplay] = useWatch({
        control,
        name: ["text", "title", "level", "shopIds", "shouldSendAsEmail", "shouldShowOnOrderDisplay"]
    });

    const messageLevels = [
        {
            label: "Ingen",
            value: MESSAGE_LEVELS.NONE
        },
        {
            label: "Blå (informationsmeddelande)",
            value: MESSAGE_LEVELS.INFO
        },
        {
            label: "Grön (nyhetsmeddelande)",
            value: MESSAGE_LEVELS.SUCCESS
        },
        {
            label: "Orange (varningsmeddelande)",
            value: MESSAGE_LEVELS.WARNING
        },
        {
            label: "Röd (felmeddelande)",
            value: MESSAGE_LEVELS.ERROR
        }
    ];

    const handleSubmit = async () => {
        if (messageType === MESSAGE_TYPE.GLOBAL) {
            setGlobalQoplaMessage({
                ...globalQoplaMessage,
                ...values,
                type: MESSAGE_TYPE.GLOBAL as string
            });
            closeModal(modalNames.QOPLA_MESSAGE_MODAL);
        } else if (messageType === MESSAGE_TYPE.SHOP) {
            const {
                selectAllShops,
                selectAllShopsWithPOSenabled,
                shouldSendAsEmail,
                shouldShowOnOrderDisplay,
                ...shopMessageInput
            } = values;
            const { data: setQoplaMessageData } = await setQoplaMessageMutation({
                variables: {
                    shopMessage: {
                        ...shopMessageInput,
                        email: shouldSendAsEmail,
                        orderDisplay: shouldShowOnOrderDisplay,
                        id: undefined,
                        type: MESSAGE_TYPE.SHOP
                    }
                }
            });
            setShopQoplaMessage(setQoplaMessageData.setQoplaMessage);
            closeModal(modalNames.QOPLA_MESSAGE_MODAL);
            await successNotification("Meddelandet har skickats!", 1400);
        }
    };

    const isDisabled =
        messageType === MESSAGE_TYPE.SHOP &&
        (!values.shopIds ||
            !values.shopIds.length ||
            !values.text ||
            (!values.shouldSendAsEmail && !values.shouldShowOnOrderDisplay));

    const submitButtonText =
        messageType === MESSAGE_TYPE.GLOBAL ? translate("saveMessage") : translate("sendMessageToSelectedRestaurants");

    return (
        <Modal isScrolling open={true} dimmer={{ inverted: true, strength: "0.5" }} size="xl">
            <ModalHeader>
                <Header size="md" margin="0">
                    {translate("message")}
                </Header>
                <ModalCloseBtn onClick={() => closeModal(modalNames.QOPLA_MESSAGE_MODAL)} />
            </ModalHeader>
            <form
                onSubmit={event => {
                    event.preventDefault();
                    handleSubmit();
                }}
            >
                <ModalBody backgroundColor="gray.100">
                    <MessageInput
                        messageFormState={values}
                        messageLevels={messageLevels}
                        messageType={messageType}
                        control={control}
                        setValue={setValue}
                    />
                </ModalBody>

                <ModalActions>
                    <Flex justifyContent="flex-end">
                        <Button style={{ marginTop: "1rem" }} type="submit" disabled={isDisabled}>
                            {submitButtonText}
                        </Button>
                    </Flex>
                </ModalActions>
            </form>
        </Modal>
    );
};
