import React from "react";
import { Formik, Field } from "formik-next";
import * as Yup from "yup";
import { NavigateFunction } from "react-router";

import { Button, FormLabel } from "Atoms";
import { FormInput, SelectOption } from "Molecules";
import { UPSERT_UMBRELLA_COMPANY } from "GraphQLMutations";
import { useMothershipMutation } from "Hooks";
import { TimePickerFormikNext } from "../../form-components/TimePickerFormikNext";
import { DAYS } from "Constants";
import { useLanguage, useQopla } from "Providers";
import { CountryLocales, ActiveHour, CountryLocale } from "Types";
import { CompanyLocaleSelect } from "../../../shared";
import { AssociatedRestaurantSelect } from "../../user/components/AssociatedRestaurantSelect";
import { buildInitialActiveHours } from "Utils";
import { validateUsersEmail } from "../../../utils/ValidateUserEmail";
import { newMothershipApolloClient } from "../../../../graphql/clients";

type UmbrellaCompany = {
    company: {
        name: string;
        countryLocale: CountryLocale;
    };
    shop: {
        name: string;
        desc: string;
        contactPerson?: {
            name: string;
            email: string;
            phoneNumber?: string;
        };
        activeHours: ActiveHour[];
    };
    user: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        confirmPassword: string;
        shopIds: SelectOption[];
    };
};

type Props = {
    navigate: NavigateFunction;
    handleCloseModal?: (companyId: string) => void;
};

export const UmbrellaCompanyForm: React.FC<Props> = ({ navigate, handleCloseModal }) => {
    const [addUmbrellaCompany] = useMothershipMutation(UPSERT_UMBRELLA_COMPANY);

    const { translate, translateWithArgument } = useLanguage();
    const { authenticatedUser } = useQopla();

    const initialActiveHours = buildInitialActiveHours(DAYS);
    const initialValues: UmbrellaCompany = {
        company: {
            name: "",
            countryLocale: CountryLocales.SWEDEN
        },
        shop: {
            name: "",
            desc: "",
            contactPerson: {
                name: "",
                email: "",
                phoneNumber: ""
            },
            activeHours: initialActiveHours
        },
        user: {
            firstName: "Umbrella",
            lastName: "User",
            email: "",
            password: "",
            confirmPassword: "",
            shopIds: []
        }
    };

    const getSchema = (): object => {
        return Yup.object().shape({
            company: Yup.object().shape({
                name: Yup.string().required("Måste fyllas i")
            }),
            shop: Yup.object().shape({
                name: Yup.string().required("Måste fyllas i"),
                contactPerson: Yup.object().shape({
                    name: Yup.string().required("Saknas"),
                    email: Yup.string().min(5, "För kort").email("Ej giltlig mailadress").required("Saknas")
                })
            }),
            user: Yup.object().shape({
                email: Yup.string()
                    .min(5, translate("tooShort"))
                    .email(translate("invalidEmailAlt"))
                    .test("email", translate("formErrorMissing"), async function (value) {
                        if (!value || value.length <= 4) {
                            return false;
                        }

                        const { path, createError } = this;
                        const result = await validateUsersEmail(newMothershipApolloClient, value);
                        return result
                            ? result
                            : createError({ path, message: translateWithArgument("emailIsAlreadyTaken", value) });
                    })
                    .required(translate("formErrorMissing")),
                password: Yup.string()
                    .min(8, translateWithArgument("formErrorAtLeastNoCharacters", 8))
                    .required(translate("formErrorMissing")),

                confirmPassword: Yup.string()
                    .min(8, translateWithArgument("formErrorAtLeastNoCharacters", 8))
                    .oneOf([Yup.ref("password"), null], translate("incorrect"))
            })
        });
    };

    const onSubmitUmbrellaCompany = async (values: UmbrellaCompany) => {
        try {
            const { confirmPassword, ...noConfirmPasswordUser } = values.user;

            const shopIds = noConfirmPasswordUser.shopIds.map((shop: SelectOption) => shop.value) ?? [];

            const updatedValues = {
                ...values,
                user: { ...noConfirmPasswordUser, shopIds: shopIds }
            };

            const { data } = await addUmbrellaCompany({
                variables: {
                    ...updatedValues
                }
            });
            if (data?.createUmbrellaCompanyAndUser) {
                if (handleCloseModal) {
                    handleCloseModal(data?.createUmbrellaCompanyAndUser);
                } else {
                    navigate(`/admin/users?companyId=${data.createUmbrellaCompanyAndUser}`);
                }
            }
        } catch (error) {
            console.error("Error when trying to add umbrella company", error);
        }
    };

    return (
        <Formik<UmbrellaCompany>
            initialValues={initialValues}
            onSubmit={async (values, _) => onSubmitUmbrellaCompany(values)}
            validationSchema={getSchema()}
        >
            {({ handleSubmit, setFieldValue, setFieldTouched, isValid, dirty, isSubmitting, values, submitCount }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormLabel mt={1} mb={2} fontSize="xl">
                            {translate("company")}
                        </FormLabel>
                        <Field
                            name="company.name"
                            formLabel={translate("companyName")}
                            isMandatory
                            fullWidth
                            wrapperProps={{ marginBottom: "1rem" }}
                            as={FormInput}
                        />
                        <CompanyLocaleSelect
                            localeValue={values.company.countryLocale}
                            fieldName={"company.countryLocale"}
                            setFieldValue={setFieldValue}
                        />
                        <FormLabel mt={4} mb={2} fontSize="xl">
                            {translate("umbrellaRestaurant")}
                        </FormLabel>
                        <Field
                            name="shop.name"
                            formLabel={translate("restaurantName")}
                            isMandatory
                            fullWidth
                            as={FormInput}
                        />
                        <FormLabel mt={4} mb={2}>
                            {translate("openingHours")}
                        </FormLabel>
                        <TimePickerFormikNext
                            name="shop.activeHours"
                            values={values.shop.activeHours}
                            hideDate={false}
                            deleteAllDay={false}
                            rename={translate("addOpeningHours")}
                        />
                        <FormLabel mt={4} mb={2} fontSize="xl">
                            {translate("contactPerson")}
                        </FormLabel>
                        <Field
                            name="shop.contactPerson.name"
                            formLabel={translate("name")}
                            isMandatory
                            fullWidth
                            as={FormInput}
                        />
                        <Field
                            name="shop.contactPerson.email"
                            placeholder={translate("emailContactPerson")}
                            formLabel="Email"
                            isMandatory
                            fullWidth
                            as={FormInput}
                        />
                        <Field
                            name="shop.contactPerson.phoneNumber"
                            formLabel={translate("telephoneNo")}
                            placeholder={translate("telephoneNo")}
                            fullWidth
                            as={FormInput}
                        />
                        <Field
                            name="shop.desc"
                            formLabel={translate("promoCode")}
                            placeholder={translate("promoCode")}
                            fullWidth
                            onChange={(event: any) =>
                                setFieldValue("shop.desc", event.target.value.toUpperCase().trim())
                            }
                            as={FormInput}
                        />
                        <FormLabel mt={4} mb={2} fontSize="xl">
                            {translate("umbrellaUser")}
                        </FormLabel>
                        <Field
                            name="user.email"
                            formLabel={translate("emailUsername")}
                            fullWidth
                            isMandatory
                            as={FormInput}
                        />
                        <Field
                            name="user.password"
                            formLabel={translate("newPassword")}
                            placeholder={translate("password")}
                            type="password"
                            fullWidth
                            isMandatory
                            as={FormInput}
                            autoComplete={"new-password"}
                        />
                        <Field
                            name="user.confirmPassword"
                            formLabel={translate("confirmNewPassword")}
                            placeholder={translate("confirmPassword")}
                            type="password"
                            fullWidth
                            isMandatory
                            as={FormInput}
                        />
                        <AssociatedRestaurantSelect
                            loggedInUser={authenticatedUser}
                            shopOptions={[]}
                            isUmbrellaCompany={true}
                            fieldName="user.shopIds"
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />

                        <Button
                            type="submit"
                            themeColor="green"
                            disabled={!(isValid && dirty) || isSubmitting || submitCount > 0}
                            fullWidth
                            mt={6}
                        >
                            {translate("add")}
                        </Button>
                    </form>
                );
            }}
        </Formik>
    );
};
