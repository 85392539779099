import React, { useState } from "react";
import { FiCopy } from "@react-icons/all-files/fi/FiCopy";
import { BsQuestionSquare } from "@react-icons/all-files/bs/BsQuestionSquare";
import { TiExport } from "@react-icons/all-files/ti/TiExport";
import { IoMdUmbrella } from "@react-icons/all-files/io/IoMdUmbrella";
import { RiMailDownloadLine } from "@react-icons/all-files/ri/RiMailDownloadLine";
import { RiMailSendLine } from "@react-icons/all-files/ri/RiMailSendLine";
import { FiGlobe } from "@react-icons/all-files/fi/FiGlobe";
import { BiData } from "@react-icons/all-files/bi/BiData";
import { AiOutlineFileSync } from "@react-icons/all-files/ai/AiOutlineFileSync";
import { GrConnect } from "@react-icons/all-files/gr/GrConnect";
import { AiOutlineTable } from "@react-icons/all-files/ai/AiOutlineTable";
import { RiListSettingsLine } from "@react-icons/all-files/ri/RiListSettingsLine";
import { RiLockPasswordLine } from "@react-icons/all-files/ri/RiLockPasswordLine";
import { RiErrorWarningLine } from "@react-icons/all-files/ri/RiErrorWarningLine";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { useNavigate } from "react-router-dom";

import { Header, Box } from "Atoms";
import { Accordion, AccordionCloseIcon, AccordionContent, AccordionItem, AccordionTitle } from "Organisms";
import { isQoplaSale } from "Utils";
import {
    RestaurantsIp,
    UmbrellaCompanyForm,
    SuperAdminGetResetPasswordLink,
    ServiceDisruptions,
    CustomerOptOutEmail,
    RestaurantsKDSStatus,
    RestaurantsEmails,
    MessageToRestaurants,
    GlobalQoplaMessage,
    SystemVariables,
    CacheAndShopUpdate,
    MissedReports
} from "./components";
import { useLanguage, useQopla, useModal } from "Providers";
import { ExportXMLData } from "./components/ExportXMLData";
import { CopyCompany } from "./components/CopyCompany";
import SwishPaymentStatus from "./components/SwishPaymentStatus";

export const MESSAGE_TYPE = {
    GLOBAL: "GLOBAL",
    SHOP: "SHOP"
};

export const MESSAGE_LEVELS = {
    NONE: "NONE",
    INFO: "INFO",
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",
    ERROR: "ERROR"
};

export type QoplaMessageType = {
    title: string;
    text: string;
    level: keyof typeof MESSAGE_LEVELS | string;
    type: keyof typeof MESSAGE_TYPE | string;
    shouldSendAsEmail: boolean;
    shouldShowOnOrderDisplay: boolean;
    id: undefined | string;
    shopIds?: string[];
};

export type GlobalQoplaObjectType = {
    id: null | string;
    closeAllExpress: boolean;
    allShopsClosed: {
        enabled: boolean;
    };
    systemMessage: {
        enabled: boolean;
        messageId: string;
    };
};

export type ShopQoplaMessageType = {
    selectAllShops?: boolean;
    selectAllShopsWithPOSenabled?: boolean;
} & QoplaMessageType;

export const messageInitialState = {
    id: undefined,
    title: "",
    text: "",
    type: "",
    level: MESSAGE_LEVELS.NONE,
    shopIds: [],
    shouldSendAsEmail: false,
    shouldShowOnOrderDisplay: false
};

const SuperAdmin: React.FC = () => {
    const navigate = useNavigate();
    const { translate } = useLanguage();
    const { authenticatedUser } = useQopla();
    const qoplaSaleUser = isQoplaSale(authenticatedUser.roles);

    const [shopQoplaMessage, setShopQoplaMessage] = useState<ShopQoplaMessageType>();
    const [globalQoplaMessage, setGlobalQoplaMessage] = useState<ShopQoplaMessageType>(messageInitialState);

    return (
        <>
            <Accordion multi>
                <AccordionItem>
                    <AccordionTitle>
                        <Header as="h4" size="md" margin="0">
                            <Box as={AiOutlineTable} mr={4} size="1.3rem" />
                            Restauranger Ip
                        </Header>
                        <AccordionCloseIcon />
                    </AccordionTitle>
                    <AccordionContent>
                        <RestaurantsIp />
                    </AccordionContent>
                </AccordionItem>
                {!qoplaSaleUser && (
                    <>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={GrConnect} mr={4} size="1.3rem" />
                                    Restauranger KDS status
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <RestaurantsKDSStatus />
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={AiOutlineFileSync} mr={4} size="1.3rem" />
                                    Saknade rapporter
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <MissedReports />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={BiData} mr={4} size="1.3rem" />
                                    Cache & Uppdateringar
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <CacheAndShopUpdate />
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={RiListSettingsLine} mr={4} size="1.3rem" />
                                    Miljövariabler
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <SystemVariables />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={FiGlobe} mr={4} size="1.3rem" />
                                    Globala inställningar
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <GlobalQoplaMessage
                                    globalQoplaMessage={globalQoplaMessage}
                                    setGlobalQoplaMessage={setGlobalQoplaMessage}
                                    setShopQoplaMessage={setShopQoplaMessage}
                                />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={RiMailSendLine} mr={4} size="1.3rem" />
                                    Meddelande till restauranger
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <MessageToRestaurants
                                    shopQoplaMessage={shopQoplaMessage}
                                    setGlobalQoplaMessage={setGlobalQoplaMessage}
                                    setShopQoplaMessage={setShopQoplaMessage}
                                />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={RiMailDownloadLine} mr={4} size="1.3rem" />
                                    Alla restaurang e-postadresser
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <RestaurantsEmails />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={IoMdUmbrella} mr={4} size="1.3rem" />
                                    Paraplyföretag
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <UmbrellaCompanyForm navigate={navigate} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={TiExport} mr={4} size="1.3rem" />
                                    Exportera XML data
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <ExportXMLData />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={FiCopy} mr={4} size="1.3rem" />
                                    Kopiera företagsartiklar
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <CopyCompany />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={BsQuestionSquare} mr={4} size="1.3rem" />
                                    {translate("checkSwishPayemnt")}
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <SwishPaymentStatus />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={RiLockPasswordLine} mr={4} size="1.3rem" />
                                    {translate("resetPasswordForUser")}
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <SuperAdminGetResetPasswordLink />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={RiErrorWarningLine} mr={4} size="1.3rem" />
                                    {`${translate("serviceDisruptions")} - (${translate("online")}) `}
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <ServiceDisruptions />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionTitle>
                                <Header as="h4" size="md" margin="0">
                                    <Box as={MdEmail} mr={4} size="1.3rem" />
                                    {translate("unregisterEmail")}
                                </Header>
                                <AccordionCloseIcon />
                            </AccordionTitle>
                            <AccordionContent>
                                <CustomerOptOutEmail />
                            </AccordionContent>
                        </AccordionItem>
                    </>
                )}
            </Accordion>
        </>
    );
};

export default SuperAdmin;
