import React from "react";

import { Flex } from "Atoms";
import { StandardText } from "../components/StandardText";
import { formatSwedishFinancialNumbers } from "PriceUtils";
import { SubscriptionOrder, Totals } from "../types";
import { useLanguage } from "Providers";
import { OnlineOrderStatus } from "Types";

export const SummaryTotals: React.FC<{ subscriptionOrders: SubscriptionOrder[]; isGroupSubscription: boolean }> = ({
    subscriptionOrders,
    isGroupSubscription
}) => {
    const { translate } = useLanguage();
    const totalValues = subscriptionOrders.reduce(
        (summary: Totals, orders: SubscriptionOrder) => {
            const isOrderConfirmed = orders.onlineOrderStatus === OnlineOrderStatus.CONFIRMED;
            summary.orderTotal += isOrderConfirmed ? orders.orderTotal : 0;
            summary.orderDiscount += isOrderConfirmed ? orders.orderDiscount : 0;
            summary.subscriptionTotal += orders.subscriptionTotal;
            return summary;
        },
        { orderDiscount: 0, orderTotal: 0, subscriptionTotal: 0 } as Totals
    );

    const totalTranslation = translate("total");

    return (
        <Flex flexDirection="column" justifyContent="flex-start" mt={6} p={5} backgroundColor="gray.100">
            {!isGroupSubscription && (
                <Flex justifyContent="space-between">
                    <StandardText>{`${totalTranslation} ${translate(
                        "subscriptionPayments"
                    )?.toLowerCase()} `}</StandardText>
                    <StandardText>{formatSwedishFinancialNumbers(totalValues.subscriptionTotal)}</StandardText>
                </Flex>
            )}
            <Flex justifyContent="space-between" mt={isGroupSubscription ? 0 : 6}>
                <StandardText>{`${totalTranslation} ${translate("originalPrice")?.toLowerCase()}`}</StandardText>
                <StandardText>{formatSwedishFinancialNumbers(totalValues.orderTotal)}</StandardText>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
                <StandardText color="orange.500">
                    {`${totalTranslation} ${translate("discount")?.toLowerCase()}`}{" "}
                </StandardText>
                <StandardText color="orange.500">{`${formatSwedishFinancialNumbers(
                    totalValues.orderDiscount
                )}`}</StandardText>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
                <StandardText>{totalTranslation}</StandardText>
                <StandardText>
                    {formatSwedishFinancialNumbers(totalValues.orderTotal - totalValues.orderDiscount)}
                </StandardText>
            </Flex>
        </Flex>
    );
};
