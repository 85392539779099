import React from "react";

import { Flex, FlexProps } from "Atoms";
import { LoadingDots } from "Molecules";
import { StandardText } from "./StandardText";

type Props = {
    label: string;
    value?: string | number;
    colour?: string;
    valueColour?: string;
    hide?: boolean;
    loading?: boolean;
} & FlexProps;

export const FlexLabelAndValue: React.FC<Props> = ({
    label,
    value,
    colour,
    valueColour,
    hide = false,
    loading = false,
    ...props
}) => {
    if (hide) {
        return <></>;
    }

    const fontSize = props.fontSize ? props.fontSize : "0.9rem";
    const valueTextColour = valueColour ? valueColour : colour;

    return (
        <Flex justifyContent="space-between" width="100%" height="fit-content" m={2} {...props}>
            <StandardText fontSize={fontSize} color={colour}>
                {label}:
            </StandardText>
            <StandardText fontSize={fontSize} color={valueTextColour}>
                {!loading ? <>{value}</> : <LoadingDots numberOfDots={3} fontSize="1rem" color="blue.500" />}
            </StandardText>
        </Flex>
    );
};
