import React, { useState, useEffect } from "react";

import { Header, Flex, Button } from "Atoms";
import {
    EVICT_ALL_CACHES,
    EVICT_USER_CACHE,
    EVICT_TERMINAL_CACHE,
    INCREMENT_ALL_SHOP_UPDATE,
    UPDATE_BAXI_CLOUD_TOKENS,
    CLEAR_REDIS_KDS
} from "GraphQLMutations";
import { useMothershipMutation } from "Hooks";
import { MessageNotification } from "../../../shared";

export const CacheAndShopUpdate: React.FC = () => {
    const initialState = {
        evictAllCaches: undefined,
        evictTerminalCache: undefined,
        incrementAllShopUpdate: undefined
    };

    const [mutationStates, setMutationStates] = useState(initialState);

    const successfulMutations =
        mutationStates &&
        Object.entries(mutationStates)
            .filter(([_, value]) => !!value)
            .map(([key, _]) => key);
    const hasSuccessfulMutations = successfulMutations.length > 0;

    useEffect(() => {
        if (hasSuccessfulMutations) {
            setTimeout(() => setMutationStates(initialState), 5000);
        }
    });

    const [evictAllCaches] = useMothershipMutation(EVICT_ALL_CACHES);
    const [evictUserCache] = useMothershipMutation(EVICT_USER_CACHE);
    const [evictTerminalCache] = useMothershipMutation(EVICT_TERMINAL_CACHE);
    const [incrementAllShopUpdate] = useMothershipMutation(INCREMENT_ALL_SHOP_UPDATE);
    const [updateBaxiCloudTokens] = useMothershipMutation(UPDATE_BAXI_CLOUD_TOKENS);
    const [resetRedisKDSPing] = useMothershipMutation(CLEAR_REDIS_KDS);

    const columns = [
        {
            content: "Evict All Caches",
            mutation: evictAllCaches
        },
        {
            content: "Evict Terminal Cache",
            mutation: evictTerminalCache
        },
        {
            content: "Evict User Cache",
            mutation: evictUserCache
        },
        {
            content: "Increment All Shop Update",
            mutation: incrementAllShopUpdate
        },
        {
            content: "Refetch Baxi Cloud Tokens",
            mutation: updateBaxiCloudTokens
        },
        {
            content: "Clear KDSPings",
            mutation: resetRedisKDSPing
        }
    ];

    const handleMutation = async (mutation: () => any) => {
        const { data } = await mutation();
        setMutationStates({
            ...initialState,
            ...data
        });
    };

    return (
        <>
            <Header as="h5" size="sm" fontWeight="normal">
                Rensa cache och trigga uppdateringar för alla restauranger.
            </Header>
            <Flex flexDirection={["column", "row"]} justifyContent="space-evenly">
                {columns.map(col => (
                    <Button m={2} key={col.content} onClick={() => handleMutation(col.mutation)}>
                        {col.content}
                    </Button>
                ))}
            </Flex>
            {hasSuccessfulMutations && (
                <MessageNotification content={`Mutation <${successfulMutations.join(",")}> successful!`} />
            )}
        </>
    );
};
