import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { MutationFunctionOptions } from "react-apollo";
import { OperationVariables } from "apollo-client";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";

import {
    Pagination,
    CurrentPage,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    PageButtons,
    DrawerActions
} from "Organisms";
import { Flex, Text, Box, NewDivider, RHTextAreaInput, Button } from "Atoms";
import { QoplaCommentLog, Shop } from "Types";
import { IDrawerComponentContext, useLanguage, useTheme, createSimpleHashId } from "Providers";
import { IconButton } from "Molecules";
import { confirmNotification } from "TempUtils";

const formatDate = (addedAt: Date) => {
    const tz = momentTimezone.tz.guess();
    const getTimeZone = momentTimezone().tz(tz).format("Z");
    return moment(addedAt).add(parseInt(getTimeZone), "h").format("YYYY-MM-DD HH:mm");
};

type Props = {
    shop: Shop;
    addShopComment: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) => Promise<void>;
    deleteShopComment: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) => Promise<void>;
    refetchShops: () => void;
};

export const QoplaCommentDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { shop, addShopComment, deleteShopComment, refetchShops },
    onCloseDrawer
}) => {
    const [qoplaComments, setQoplaComments] = useState<QoplaCommentLog[]>(shop.qoplaCommentLogs ?? []);
    const { colors } = useTheme();
    const { translate } = useLanguage();
    const [openDrawer, setOpenDrawer] = useState(true);
    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        onCloseDrawer();
    };
    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        resetField
    } = useForm<{ qoplaComment: string }>({
        defaultValues: { qoplaComment: "" },
        reValidateMode: "onSubmit"
    });
    const saveQoplaComment = async (comment: string) => {
        await addShopComment({
            variables: { shopId: shop.id, qoplaComment: comment },
            update: (_, { data }) => {
                if (data?.addQoplaCommentToShop) {
                    setQoplaComments(data.addQoplaCommentToShop);
                    refetchShops();
                }
            }
        });
    };
    const deleteQoplaComment = async (comment: QoplaCommentLog) => {
        await deleteShopComment({
            variables: {
                shopId: shop.id,
                addedAt: moment(comment.addedAt).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                qoplaComment: comment.qoplaComment,
                userName: comment.username
            },
            update: (_, { data }) => {
                if (data?.deleteQoplaCommentToShop) {
                    setQoplaComments(data.deleteQoplaCommentToShop);
                    refetchShops();
                    if (data.deleteQoplaCommentToShop.length === 0) {
                        onCloseDrawer();
                    }
                }
            }
        });
    };

    const onSubmit = async (formValue: { qoplaComment: string }) => {
        const { qoplaComment } = formValue;
        if (qoplaComment.length > 0) {
            await saveQoplaComment(qoplaComment);
            resetField("qoplaComment");
        }
    };
    /** Sort comments with latest first */
    !!qoplaComments?.length &&
        qoplaComments?.sort((comment1, comment2) =>
            comment1.addedAt > comment2.addedAt ? -1 : comment1.addedAt < comment2.addedAt ? 1 : 0
        );
    return (
        <Drawer open={openDrawer} onClose={handleCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Box>{shop.name}</Box>
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    <Flex direction="column" height="100%">
                        <Pagination items={qoplaComments ?? []} numberPerPage={isMobile ? 5 : 8}>
                            <PageButtons
                                showMaxPage={5}
                                pageButtonTheme={{
                                    WRAPPER: {
                                        border: "none",
                                        boxShadow: "none",
                                        marginBottom: "1.5rem",
                                        marginTop: "0"
                                    },
                                    BUTTONS: { color: "gray.900" },
                                    HOVERCOLOUR: colors.blue["100"]
                                }}
                            />

                            <CurrentPage
                                width="100%"
                                wrapperProps={{ justifyContent: "flex-start" }}
                                renderItem={(comment: QoplaCommentLog, i: number) => {
                                    const key = createSimpleHashId([comment.addedAt.toString(), comment.qoplaComment]);
                                    return (
                                        <React.Fragment key={key}>
                                            <Flex direction="column" ml={2} mt={4} mb={4} height="max-content">
                                                <Flex fontWeight="bold" justifyContent="space-between">
                                                    <Text mr={4}>{formatDate(comment?.addedAt)}</Text>
                                                    <Text>{comment?.username}</Text>
                                                    <IconButton
                                                        mt="-6px"
                                                        themeColor="white"
                                                        color={colors.black["200"]}
                                                        fontSize="3xl"
                                                        size="md"
                                                        icon={IoIosClose}
                                                        onClick={() =>
                                                            confirmNotification(
                                                                `${translate("delete")} `,
                                                                `"${comment?.qoplaComment}"`,
                                                                `${translate("yesDelete")}`
                                                            ).then(async result => {
                                                                if (result.value) {
                                                                    await deleteQoplaComment(comment);
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Flex>
                                                <Text fontStyle="oblique">{comment?.qoplaComment}</Text>
                                            </Flex>
                                            <NewDivider color="gray.400" />
                                        </React.Fragment>
                                    );
                                }}
                            />
                        </Pagination>
                    </Flex>
                </DrawerBody>
                <DrawerActions marginTop="auto">
                    <Box ml={2}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <RHTextAreaInput
                                control={control}
                                name="qoplaComment"
                                formLabel={translate("comment")}
                                isFullWidth
                                m={0}
                            />
                            <Button isLoading={isSubmitting} type="submit" themeColor="green" fullWidth>
                                {translate("save")}
                            </Button>
                        </form>
                    </Box>
                </DrawerActions>
            </DrawerContent>
        </Drawer>
    );
};
