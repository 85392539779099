import React from "react";
import { ApolloQueryResult, OperationVariables } from "apollo-client";
import { QueryHookOptions } from "react-apollo";
import { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import { useField } from "formik-next";
import { DocumentNode } from "graphql";

import { Box, Text, FormLabel, FormErrorMessage, FormHelperText } from "Atoms";
import { SelectOption } from "Molecules";
import { newMothershipApolloClient } from "../../../../../graphql/clients";

export const searchDatabase = async (
    query: DocumentNode,
    options?: QueryHookOptions<SelectOption, OperationVariables>
): Promise<ApolloQueryResult<any>> => {
    return await newMothershipApolloClient.query({
        query: query,
        ...options,
        fetchPolicy: "network-only"
    });
};

type Props = {
    name: string;
    formLabel?: string;
    isMandatory?: boolean;
    isMulti?: boolean;
    isDisabled?: boolean;
    placeHolder?: string;
    helperText?: string;
    noOptionsMessage?: ((obj: { inputValue: string }) => string | null) | undefined;
    onFieldChange?: (value: SelectOption | SelectOption[]) => void;
    searchLoadingOptions: (searchInput: string) => Promise<SelectOption[]>;
};

const selectStyles: StylesConfig<any, any> = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
    },
    menuPortal: base => ({ ...base, zIndex: 9999 })
};

export const AsyncSearchSelect: React.FC<Props> = ({
    name,
    formLabel,
    isDisabled,
    isMandatory,
    placeHolder,
    isMulti = false,
    helperText,
    onFieldChange,
    noOptionsMessage,
    searchLoadingOptions
}) => {
    const [field, meta, helpers] = useField<Array<any>>(name);

    const errorTxt = meta.error ? meta.error : "";

    const onChange = (newOption: Array<SelectOption> | null) => {
        if (!newOption) {
            helpers.setValue([]);
        } else {
            helpers.setValue(newOption);
            if (onFieldChange) {
                onFieldChange(newOption);
            }
        }
    };

    return (
        <Box mb={6}>
            {formLabel && (
                <FormLabel>
                    {formLabel}
                    {isMandatory && (
                        <Text as="span" color="red.500" ml={2}>
                            *
                        </Text>
                    )}
                </FormLabel>
            )}
            {/** @ts-ignore */}
            <AsyncSelect
                {...field}
                noOptionsMessage={noOptionsMessage}
                placeholder={placeHolder}
                loadOptions={searchLoadingOptions}
                onChange={onChange}
                isMulti={isMulti}
                isDisabled={isDisabled}
                menuPortalTarget={document.body}
                isSearchable
                styles={selectStyles}
            />
            {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
