import React from "react";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";
import { CSVLink } from "react-csv";

import { Button, Text } from "Atoms";
import { useLanguage } from "Providers";

type Props = {
    filename: string;
    csvHeaders: { label: string; key: string }[];
    csvData: any[];
    size?: any;
};

export const CSVDownloadButton: React.FC<Props> = ({ filename, csvHeaders, csvData, ...rest }) => {
    const { translate } = useLanguage();
    const isDisabled = !csvData || csvData.length == 0;

    return (
        <Button
            w={{
                base: "full",
                lg: "auto"
            }}
            {...rest}
            leftIcon={FiDownload}
            themeColor="green"
            disabled={isDisabled}
            mb={2}
            mt={2}
        >
            {!isDisabled ? (
                <>
                    {/** @ts-ignore */}
                    <CSVLink filename={filename} headers={csvHeaders} data={csvData} separator={";"}>
                        <Text color="white">{translate("download")}</Text>
                    </CSVLink>
                </>
            ) : (
                translate("download")
            )}
        </Button>
    );
};
