import React from "react";
import moment from "moment";
import { Form, Formik } from "formik-next";

import { BaseBoxProps, Box, Button, Flex, Textarea } from "Atoms";
import { Cancelling, CANCELORABORT, CANCELPERIOD, UserSubscriptionOverview } from "../types";
import { useLanguage } from "Providers";
import { FlexLabelAndValue } from "./FlexLabelAndValue";
import { SubscriptionType } from "Types";
import { StandardText } from "./StandardText";
import { preWrittenCancelReasons } from "../../../../../customer/pages/userAccount/components/UserAccountSubscriptions/components/UserAccountCancelSubscriptionModal";
import { FormInput } from "Molecules";

type Props = {
    onOpenCancel: (status: Cancelling) => Promise<void>;
    userSubscription: UserSubscriptionOverview;
} & BaseBoxProps;

export const CancelBox: React.FC<Props> = ({ onOpenCancel, userSubscription, ...props }) => {
    const { translate } = useLanguage();
    const { chosenPricePlan } = userSubscription;
    const isGroupSubscription = userSubscription.subscriptionType === SubscriptionType.GROUP;
    const nextPaymentMoment = moment
        .utc(userSubscription.latestRecurringPaymentTimestamp)
        .add(chosenPricePlan.paymentFrequencyInMonths, "months");

    const isPendingCancellation = !!userSubscription.subscriptionCancelReason;
    const timeUntilNextPayment = nextPaymentMoment.diff(moment(), "days");
    const nextPaymentDateString = nextPaymentMoment.format("YYYY-MM-DD");

    const hideCancelLater = isGroupSubscription || isPendingCancellation;

    return (
        <Flex
            direction="column"
            height="auto"
            rounded="lg"
            p={4}
            borderStyle="solid"
            borderWidth="1px"
            position="relative"
            bottom="5px"
            zIndex="9999"
            borderColor={"gray.300"}
            backgroundColor="white"
            boxShadow="md"
            width="100%"
            {...props}
        >
            <Formik<any>
                initialValues={{
                    type: CANCELORABORT.ABORT,
                    when: CANCELPERIOD.NONE,
                    reason: userSubscription?.subscriptionCancelReason?.reason ?? "",
                    detailedReason: userSubscription?.subscriptionCancelReason?.detailedReason ?? ""
                }}
                onSubmit={async values => {
                    onOpenCancel({
                        ...values,
                        type: values.when !== CANCELPERIOD.NONE ? CANCELORABORT.CANCEL : CANCELORABORT.ABORT
                    });
                }}
            >
                {({ values, setFieldValue, isValid, isSubmitting }) => (
                    <Form>
                        <StandardText fontSize="1rem" color="red.500" ml={0} mb={4}>
                            {translate("promptCancelSubscription")}
                        </StandardText>
                        <StandardText fontSize="1rem" color="red.500" ml={0} mb={6}>
                            {translate("rememberRefund")}
                        </StandardText>
                        <FlexLabelAndValue
                            label={translate("cancelSubscriptionText")}
                            value={`${timeUntilNextPayment} ${translate("days")} `}
                            hide={isGroupSubscription}
                        />
                        <FlexLabelAndValue
                            label={translate("endOnPaymentDate")}
                            value={nextPaymentDateString}
                            hide={isGroupSubscription}
                        />
                        <Box>
                            <Flex wrap="wrap" h="auto" mb={2} mt={4}>
                                {preWrittenCancelReasons.map(preWrittenReason => {
                                    let styles = {
                                        borderColor: "gray.600",
                                        color: "gray.600",
                                        backgroundColor: "transparent"
                                    };
                                    const isSelected = preWrittenReason === values.reason;
                                    if (isSelected) {
                                        styles = {
                                            borderColor: "blue.600",
                                            color: "blue.600",
                                            backgroundColor: "blue.200"
                                        };
                                    }
                                    return (
                                        <Button
                                            key={preWrittenReason}
                                            size="sm"
                                            variant="outline"
                                            fontWeight="normal"
                                            onClick={() => setFieldValue("reason", preWrittenReason)}
                                            mr={2}
                                            mb={2}
                                            {...styles}
                                        >
                                            {translate(preWrittenReason)}
                                        </Button>
                                    );
                                })}
                            </Flex>

                            <FormInput
                                name="detailedReason"
                                formLabel={translate("whyCancelingSubscription")}
                                component={Textarea}
                                fullWidth
                                wrapperProps={{ mb: 0 }}
                                resize="none"
                            />
                        </Box>
                        <Flex
                            direction={["column", "column", "row"]}
                            justifyContent={["flex-start", "flex-start", "space-between"]}
                            width="100%"
                            mt={5}
                        >
                            {!hideCancelLater && (
                                <Button
                                    themeColor="red"
                                    variant="outline"
                                    type="submit"
                                    disabled={!isValid || isGroupSubscription}
                                    isLoading={isSubmitting}
                                    mb={[2, 2, 0]}
                                    onClick={() => setFieldValue("when", CANCELPERIOD.LATER)}
                                >
                                    {translate("endBeforePeriodDate")}
                                </Button>
                            )}

                            <Button
                                themeColor="red"
                                variant="outline"
                                type="submit"
                                isLoading={isSubmitting}
                                mb={[2, 2, 0]}
                                onClick={() => setFieldValue("when", CANCELPERIOD.NOW)}
                            >
                                {translate("endNow")}
                            </Button>
                            <Button
                                themeColor="blue"
                                mb={[1, 1, 0]}
                                onClick={() => onOpenCancel({ when: CANCELPERIOD.NONE, type: CANCELORABORT.ABORT })}
                            >
                                {translate(isPendingCancellation ? "back" : "abort")}
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Flex>
    );
};
