import moment from "moment";
import {
    Order,
    OrderProduct,
    Subscriptions,
    SubscriptionStatus,
    UserSubscriptionAndOrderDTO,
    SubscriptionCancelReason,
    SubscriptionType,
    GroupSubscription,
    OnlineOrderStatus
} from "Types";

/** QUERY TYPES */
export namespace Query {
    export type UserSubscriptions = {
        getAllUserSubscriptionsBySearchCriteria: UserSubscriptionOverview[];
    };

    export type CompanySubscriptions = {
        getSubscriptionsByCompanyId: Subscriptions;
    };

    export type Orders = {
        getOrdersByOrderIds: Order[];
    };

    export type FullGroupSubscription = {
        getGroupSubscriptionBySubIdAndUserSubId: GroupSubscription;
    };
}

/** Search Types */
export namespace Search {
    export type Subscriptions = {
        subscriptionIds: string[];
        subscriptionTypes: SubscriptionType[];
        subscriptionStatus: SubscriptionStatus[];
        userEmail: string;
    };

    export type Criteria = {
        searchCriteria: Subscriptions;
        hasAccessToSearchFilter: boolean;
        onGetUserSubscriptions: (search: Subscriptions) => void;
    };
}

/** SUBSCRIPTION OVERVIEW TYPES */
export type UserSubscriptionOverview = {
    subscriptionName?: string;
    customerName?: string;
    customerEmail?: string;
    colour?: string;
} & UserSubscriptionAndOrderDTO;

export type Totals = {
    orderTotal: number;
    orderDiscount: number;
    subscriptionTotal: number;
};

export type SubscriptionOrder = {
    orderId: string;
    purchaseDate: moment.Moment;
    subscriptionPayments?: Order;
    orderProducts?: OrderProduct[];
    onlineOrderStatus: OnlineOrderStatus;
} & Totals;

export type ExtendedOrder = Order & {
    totalAmountLeft?: number;
    refundedAmount?: number;
    isFullyRefunded?: boolean;
};

export const subscriptionStatusToText: Record<SubscriptionStatus, string> = {
    ACTIVE: "subscriptionStatusActive",
    CANCELLED: "subscriptionStatusCancelled",
    AT_RISK: "subscriptionStatusMissingPayment",
    PENDING: "subscriptionStatusPending",
    INITIAL_PAYMENT_FAILED: "subscriptionStatusPaymentFailed"
};

/* 
REFUNDS AND CANCEL
**/
export enum CANCELPERIOD {
    NOW = "NOW",
    LATER = "LATER",
    NONE = "NONE"
}

export enum CANCELORABORT {
    CANCEL = "CANCEL",
    ABORT = "ABORT"
}

export enum SUBMIT_TYPE {
    REFUND = "REFUND",
    CANCEL = "CANCEL",
    NONE = "NONE"
}

export type RefundOrCancel = {
    userSubscriptionId: string;
    orderPaymentid: string;
    maxRefundAmount: string | number;
    vatRate: number;
    amount: string | number;
    submitType: SUBMIT_TYPE;
    subscriptionType: SubscriptionType;
};

export type Cancelling = {
    type: CANCELORABORT;
    when: CANCELPERIOD;
    date?: moment.Moment;
};

export type CancelReason = Omit<SubscriptionCancelReason, "cancelledTimestamp"> & {
    cancelledTimestamp: number;
};
