import React from "react";
import ReactTable from "react-table";

import { Header, Spinner, Flex } from "Atoms";
import { filterCaseInsensitiveReactTable } from "TempUtils";
import { swedishReactTableProperties } from "../../../utils/TextFormat";
import { formatDateToLocal } from "Utils";
import { GET_KDS_PING } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { useAccordionItemContext } from "Organisms";

export const RestaurantsKDSStatus: React.FC = () => {
    const { isExpanded } = useAccordionItemContext();

    const { data: kdsPing, loading: kdsPingLoading } = useMothershipQuery(GET_KDS_PING, {
        fetchPolicy: "network-only",
        skip: !isExpanded
    });

    if (kdsPingLoading || !kdsPing) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        <>
            <Header as="h5" size="sm" fontWeight="normal">
                De senaste registrerade KDS status
            </Header>
            <pre>
                <code
                    style={{
                        backgroundColor: "#eee",
                        border: "1px solid #999",
                        display: "block",
                        padding: "2em",
                        whiteSpace: "pre-wrap"
                    }}
                >
                    {/** @ts-ignore */}
                    <ReactTable
                        data={kdsPing?.getSuperAdminKDSList ?? []}
                        className="-striped -highlight"
                        filterable
                        defaultFilterMethod={filterCaseInsensitiveReactTable}
                        {...swedishReactTableProperties}
                        columns={[
                            { Header: "ID", accessor: "shopId" },
                            {
                                Header: "Senaste lagrad i DB",
                                accessor: "lastStoredInDb",
                                Cell: row => formatDateToLocal(row.value)
                            },
                            {
                                Header: "Senaste uppdatering",
                                accessor: "lastUpdated",
                                Cell: row => formatDateToLocal(row.value)
                            },
                            {
                                Header: "Antal ping",
                                accessor: "numberOfPings"
                            }
                        ]}
                    />
                </code>
            </pre>
        </>
    );
};
