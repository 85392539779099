import React from "react";
import { HiOutlineChevronLeft } from "@react-icons/all-files/hi/HiOutlineChevronLeft";
import { HiOutlineChevronRight } from "@react-icons/all-files/hi/HiOutlineChevronRight";

import { usePagination } from "Organisms";
import { Button, Flex, FlexProps } from "Atoms";
import { IconButton } from "Molecules";

export const PageButtons: React.FC<{ showMaxPage?: number; wrapperProps?: FlexProps }> = ({
    showMaxPage = 10,
    wrapperProps
}) => {
    const { currentPage, maxPage, next, prev, goToPage } = usePagination();

    const pageContent = () => {
        let totalShowingPages = showMaxPage;
        if (showMaxPage > maxPage) {
            totalShowingPages = maxPage;
        }
        const start = currentPage - Math.floor(totalShowingPages / 2);
        const startMx = Math.max(start, 1);
        const startMin = Math.min(startMx, 1 + maxPage - totalShowingPages);

        return Array.from({ length: totalShowingPages }, (_, i) => startMin + i);
    };

    const pageNumbers = pageContent();
    const hoverColour = "#EDF2F7";

    if (maxPage <= 1) {
        return <></>;
    }

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            background="white"
            marginLeft="auto"
            marginRight="auto"
            position="sticky"
            bottom="10px"
            width="max-content"
            zIndex={1100}
            marginTop="1.5rem"
            borderRadius="0.5rem"
            borderColor="gray.300"
            borderStyle="solid"
            borderWidth="1px"
            boxShadow="md"
            height="auto"
            {...wrapperProps}
        >
            <IconButton
                as={HiOutlineChevronLeft}
                _hover={{ background: hoverColour, color: "teal.500" }}
                style={{ cursor: "pointer" }}
                background="none"
                border="none"
                color="gray.900"
                onClick={prev}
                width="1.5rem"
                height="1.5rem"
                marginLeft={2}
            />

            {pageNumbers?.map((value: number) => {
                return (
                    <Button
                        key={value}
                        _hover={{ background: hoverColour }}
                        onClick={() => goToPage(value)}
                        background="none"
                        style={{ cursor: "pointer" }}
                        color={currentPage === value ? "blackAlpha" : "gray.400"}
                        fontSize="1.5rem"
                    >
                        {value}
                    </Button>
                );
            })}

            <IconButton
                as={HiOutlineChevronRight}
                _hover={{ background: hoverColour, color: "teal.500" }}
                style={{ cursor: "pointer" }}
                background="none"
                color="gray.900"
                value="next"
                onClick={next}
                width="1.5rem"
                height="1.5rem"
                marginRight={2}
            />
        </Flex>
    );
};
