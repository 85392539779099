import React from "react";

import { Header as Text, HeaderProps } from "Atoms";

export const StandardText: React.FC<HeaderProps> = ({ ...props }) => {
    return (
        <Text
            justifySelf={["center", "center", "center", "flex-start"]}
            color="gray.900"
            letterSpacing="1px"
            size="xs"
            m={0}
            p={[1, 1, 1, 0]}
            ml={4}
            wordBreak="break-word"
            {...props}
        />
    );
};
