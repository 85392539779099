import React, { useState } from "react";
import moment from "moment";

import { Order, RefundStatus } from "Types";
import { BaseBoxProps, Button, Flex } from "Atoms";
import { FlexLabelAndValue } from "./FlexLabelAndValue";
import { formatSwedishFinancialNumbers } from "PriceUtils";
import { ReceiptType } from "Constants";
import { sortByDates } from "../utils/sortingSubscriptions";
import { ExtendedOrder } from "../types";
import { useLanguage } from "Providers";
import { PaymentStatus } from "../../../../../generated/graphql";

export const RefundBox: React.FC<
    {
        subscriptionPayments?: ExtendedOrder[];
        onSelectedPayment: React.Dispatch<React.SetStateAction<Order | null>>;
        isUpdating?: boolean;
    } & BaseBoxProps
> = ({ subscriptionPayments, onSelectedPayment, isUpdating = false, ...props }) => {
    const { translate } = useLanguage();
    const [isSelected, setIsSelected] = useState<boolean>(false);

    if (!subscriptionPayments?.length) {
        return <></>;
    }

    let latestPayment = subscriptionPayments
        .sort(sortByDates)
        .find(sub => sub.paymentStatus === PaymentStatus.FullyPaid && sub.receiptType === ReceiptType.PURCHASE);
    let disableChooseButton = false;
    const refundedStatuses = [RefundStatus.FullyRefunded, RefundStatus.PartiallyRefunded];
    const refundedPayments =
        subscriptionPayments.filter(
            sub => sub?.referenceOrderId === latestPayment?.id && refundedStatuses.includes(sub.refundStatus)
        ) ?? null;

    if (latestPayment && refundedPayments) {
        const { totalAmount: originalAmount = 0 } = latestPayment;
        const totalRefundedAmount = refundedPayments.reduce((total: number, payment: Order) => {
            return (total += payment.totalAmount);
        }, 0);

        const totalLeft = originalAmount + totalRefundedAmount;
        const isFullyRefunded = totalLeft === 0;
        disableChooseButton = isFullyRefunded;
        latestPayment = {
            ...latestPayment,
            totalAmountLeft: totalLeft,
            refundedAmount: totalRefundedAmount,
            isFullyRefunded: isFullyRefunded
        };
    } else if (latestPayment) {
        latestPayment = {
            ...latestPayment,
            totalAmountLeft: latestPayment.totalAmount
        };
    }

    if (!latestPayment) {
        return <></>;
    }

    const onHandleSelection = (latestPayment: Order) => {
        setIsSelected(!isSelected);
        onSelectedPayment(!isSelected ? latestPayment : null);
    };

    return (
        <Flex
            direction="column"
            height="auto"
            p={4}
            rounded="lg"
            borderStyle="solid"
            borderWidth="1px"
            position="relative"
            bottom="5px"
            zIndex="9999"
            borderColor={isSelected ? "red.500" : "gray.300"}
            backgroundColor="white"
            boxShadow="md"
            width="100%"
            {...props}
        >
            <FlexLabelAndValue
                label={translate("latestPaymentDate")}
                value={moment(latestPayment.purchaseDate).format("YYYY-MM-DD")}
            />
            {refundedPayments ? (
                <>
                    <FlexLabelAndValue
                        label={translate("altSum")}
                        value={formatSwedishFinancialNumbers(latestPayment.totalAmount)}
                    />
                    <FlexLabelAndValue
                        color="red.500"
                        label={latestPayment.isFullyRefunded ? translate("refund") : translate("partialRefund")}
                        value={formatSwedishFinancialNumbers(latestPayment.refundedAmount ?? 0)}
                        loading={isUpdating}
                    />
                    <FlexLabelAndValue
                        label={translate("total")}
                        value={formatSwedishFinancialNumbers(latestPayment.totalAmountLeft ?? 0)}
                        loading={isUpdating}
                    />
                </>
            ) : (
                <FlexLabelAndValue
                    label={translate("total")}
                    value={formatSwedishFinancialNumbers(latestPayment.totalAmount)}
                />
            )}

            <Button
                mt={4}
                marginLeft="auto"
                onClick={() => onHandleSelection(latestPayment!)}
                disabled={disableChooseButton}
            >
                {translate("managePayment")}
            </Button>
        </Flex>
    );
};
