import { RowColour } from "../../../customerFeedback/types";
import { getNRowNumber } from "../../../customerFeedback/utils/feedbackFunctions";
import { Subscriptions, SubscriptionStatus, SubscriptionType } from "Types";
import { UserSubscriptionOverview } from "../types";
import { string2Hex } from "Utils";
import { TranslateFunc, useLanguage } from "Providers";

export type ColourTiles = {
    colour: string;
    id: string;
};

/**
 * Function get colours for the tiles from the a nicer set
 * **** did this function because the colours in the string hex weren't nice!
 * @param {Subscriptions} subscriptions
 * @returns
 */
export const getSubscriptionColour = (subscriptions?: Subscriptions) => {
    const subscriptionIds = [...new Set(subscriptions?.map(value => value.id))];
    let theColourValue = { colour: 500 };
    return subscriptionIds?.reduce((colours: ColourTiles[], id: string, index: number) => {
        const rowNumber = getNRowNumber(index, 5);
        const rowColour = RowColour[rowNumber];
        const colourValue = rowColour.split(".");
        let colour = { colour: colourValue[0] + "." + theColourValue.colour, id: id };
        if (theColourValue.colour <= 0) {
            colour = { colour: string2Hex(id ?? "okänt namn").toString(), id: id };
        }
        if ((index + 1) % 5 === 0) {
            theColourValue.colour += -100;
        }
        return [...colours, colour];
    }, []);
};

/**
 * Function to only include first letter of surname
 * @param {string} name
 * @returns {string}
 */
const getAbbreviatedName = (name: string) => {
    const split = name ? name.split(" ") : "anonym".split(" ");
    if (split.length === 2) {
        return `${split[0].trim()} ${split[1].trim().charAt(0).toUpperCase()}` ?? split[0];
    } else if (split.length === 3) {
        return `${split[0].trim()} ${split[2].trim().charAt(0).toUpperCase()}` ?? split[0];
    }
    return name;
};

/**
 * Function return colour for text for subscription Status
 * @param status
 * @returns
 */
export const getSubscriptionStatusColour = (status: SubscriptionStatus) => {
    switch (status) {
        case SubscriptionStatus.ACTIVE: {
            return "green.500";
        }
        case SubscriptionStatus.AT_RISK: {
            return "orange.500";
        }
        case SubscriptionStatus.CANCELLED: {
            return "red.500";
        }
        case SubscriptionStatus.PENDING: {
            return "blue.500";
        }
        case SubscriptionStatus.INITIAL_PAYMENT_FAILED: {
            return "teal.500";
        }
    }
};

/**
 * Function to extend user subscriptions to add some additional information
 * to make more accessible
 * E.G subscription name, customer email, customer name
 * @param userSubscriptions
 * @param companySubscriptions
 * @returns
 */
export const extendSubscriptionsOverView = (
    userSubscriptions: UserSubscriptionOverview[] | undefined,
    companySubscriptions: Subscriptions | undefined,
    translate: TranslateFunc
) => {
    return (
        userSubscriptions?.map(sub => {
            let customerName = translate("noCustomerName");
            let email = translate("noCustomerEmail");
            const subscriptionName =
                companySubscriptions?.find(compSub => compSub.id === sub.subscriptionId)?.name ?? "okänt namn";
            if (sub.subscriptionPayments?.length && sub.subscriptionType === SubscriptionType.SINGLE) {
                const { onlineContactInformation } = sub.subscriptionPayments[0].userInformation;
                customerName =
                    onlineContactInformation.name.trim() +
                        " " +
                        onlineContactInformation.lastName?.trim()?.charAt(0)?.toUpperCase() ?? "A";
                email = onlineContactInformation.email ?? translate("noCustomerEmail");
            }

            if (sub.subscriptionType === SubscriptionType.GROUP && sub.customerName && sub.customerEmail) {
                customerName = getAbbreviatedName(sub.customerName);
                email = sub.customerEmail;
            }

            return {
                ...sub,
                subscriptionName: subscriptionName,
                customerName: customerName,
                customerEmail: email
            };
        }) ?? []
    );
};

/**
 * Function to get translation for the reason
 * @param subCancelReason
 * @returns
 */
export const getTranslationForReason = (subCancelReason: any) => {
    const { translate, isMissingTranslationKey } = useLanguage();
    const hasMissingTranslation = isMissingTranslationKey(subCancelReason?.reason);
    return hasMissingTranslation ? subCancelReason?.reason : translate(subCancelReason?.reason);
};
