import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Modal, ModalBody, ModalHeader, ModalCloseBtn } from "Molecules";
import { Button, Box, RHFormInput, Flex } from "Atoms";
import { IModalContext, useLanguage } from "Providers";
import { modalNames } from "Constants";
import { ShopTable } from "Types";

type Props = {
    handleAddQrTableBatch: (tablesBatch: ShopTable[]) => void;
};

type QRTableOptionsType = {
    tableName: string;
    tableNumberFrom: number;
    tableNumberTo: number;
};

const validationSchema = Yup.object().shape({
    tableNumberFrom: Yup.number().required().min(0),
    tableNumberTo: Yup.number().required().min(1)
});

export const QrTableBatchModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { translate, translateWithArgument } = useLanguage();

    const { handleAddQrTableBatch } = modalContent;

    const {
        control,
        formState: { isValid },
        setValue,
        handleSubmit
    } = useForm<QRTableOptionsType>({
        defaultValues: {
            tableName: "",
            tableNumberFrom: 1,
            tableNumberTo: 10
        },
        resolver: yupResolver(validationSchema),
        mode: "onBlur"
    });

    const nrFrom = useWatch({ control, name: "tableNumberFrom" });
    const nrTo = useWatch({ control, name: "tableNumberTo" });

    useEffect(() => {
        if (nrFrom > nrTo) {
            setValue("tableNumberTo", nrFrom);
        }
    }, [nrFrom]);

    const amountOfTables = nrTo - nrFrom + 1;

    const onSubmit = (values: QRTableOptionsType) => {
        const tablesBatch = [];
        for (let i = +nrFrom; i <= +nrTo; i++) {
            tablesBatch.push({
                id: uuidv4(),
                name: `${values.tableName} ${i}`
            });
        }
        handleAddQrTableBatch(tablesBatch as ShopTable[]);
        closeModal(modalNames.QR_TABLE_BATCH_MODAL);
    };

    return (
        <Modal open>
            <ModalHeader>
                <Box>{translate("addBatchOfTables")}</Box>
                <ModalCloseBtn onClick={() => closeModal(modalNames.QR_TABLE_BATCH_MODAL)} />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <RHFormInput
                        control={control}
                        name="tableName"
                        formLabel={translate("baseTableName")}
                        fullWidth
                        placeholder={translate("table")}
                        tooltipLabelText={translate("baseTableNameTooltip")}
                    />
                    <Box mb={2}>{translate("tableNumberRange")}</Box>
                    <Flex alignItems={"center"}>
                        <Box mr={2}>{translate("from")}</Box>
                        <RHFormInput control={control} name="tableNumberFrom" type="number" wrapperProps={{ mb: 0 }} />
                        <Box mr={2} ml={6}>
                            {translate("to")}
                        </Box>
                        <RHFormInput control={control} name="tableNumberTo" type="number" wrapperProps={{ mb: 0 }} />
                    </Flex>
                    <Button
                        mt={8}
                        themeColor="green"
                        fullWidth
                        type="submit"
                        isDisabled={!isValid || amountOfTables < 1}
                    >
                        {translateWithArgument("createAndSaveTablesWithArg", amountOfTables > 0 ? amountOfTables : 0)}
                    </Button>
                </form>
            </ModalBody>
        </Modal>
    );
};
