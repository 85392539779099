import React, { useState, useEffect } from "react";
import moment from "moment";

import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    DrawerActions,
    Pagination,
    CurrentPage
} from "Organisms";
import { IDrawerComponentContext, useLanguage } from "Providers";
import { OnlineOrderStatus, Order, OrderProduct, OrderType, SubscriptionType } from "Types";
import { useMothershipQuery } from "Hooks";
import { GET_ORDERS_BY_ORDER_IDS } from "GraphQLQueries";
import { FadeInBox } from "../components/FadeInBox";
import { OrderTile } from "../components/OrderTile";
import { sortByDates } from "../utils/sortingSubscriptions";
import { Flex, NewDivider, Skeleton } from "Atoms";
import { PageButtons } from "../components/PageButtons";
import { SummaryTotals } from "../components/SummaryTotals";
import { Query, SubscriptionOrder, Totals, UserSubscriptionOverview } from "../types";

type Props = {
    userSubscription: UserSubscriptionOverview;
};

export const UserSubscriptionOrdersDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { userSubscription },
    onCloseDrawer
}) => {
    const { translate } = useLanguage();
    const [subscriptionOrders, setSubscriptionOrders] = useState<SubscriptionOrder[] | null>(null);
    const isGroupSubscription = userSubscription.subscriptionType === SubscriptionType.GROUP;

    /** Get all orders by ids in the subscription redemption */
    const { data: orders, loading } = useMothershipQuery<Query.Orders>(GET_ORDERS_BY_ORDER_IDS, {
        variables: {
            orderIds: [...userSubscription.subscriptionRedemptionOrders, ...userSubscription.upgradePaymentOrders!]
        }
    });

    const getSubscriptionOrderProducts = () => {
        const onlineStatusAllowed = [OnlineOrderStatus.CANCELLED, OnlineOrderStatus.CONFIRMED];
        const allOrders = orders?.getOrdersByOrderIds?.filter(sub =>
            onlineStatusAllowed.includes(sub.onlineOrderStatus)
        );
        let subscriptionProducts =
            allOrders?.reduce((products: SubscriptionOrder[], order: Order) => {
                const { subscriptionMeta, orderProducts } = order;
                /** Get products in an order that has the subscription id in the combined discounts
                 * Note: needs to do this as you could have products that aren't part of the subscription
                 */
                const subscriptionProducts = orderProducts.filter(product =>
                    product.combinedDiscounts?.some(discount => discount.discountId === subscriptionMeta.subscriptionId)
                );

                /** Calculate the totals of those products */
                const orderTotal = subscriptionProducts.reduce(
                    (total: Totals, orderProduct: OrderProduct) => {
                        const { combinedDiscounts } = orderProduct;
                        const single = combinedDiscounts?.find(
                            value => value.discountId === subscriptionMeta.subscriptionId
                        );
                        if (single) {
                            total.orderTotal += single.discountedFrom ?? 0;
                            total.orderDiscount += single.discountValue;
                        }
                        return total;
                    },
                    { orderTotal: 0, orderDiscount: 0 } as Totals
                );

                /** Create the subscription order */
                const subscriptionOrder =
                    order.orderType === OrderType.SUBSCRIPTION
                        ? ({
                              orderId: order.id,
                              purchaseDate: moment(order.purchaseDate),
                              subscriptionPayments: order,
                              orderTotal: 0,
                              orderDiscount: 0,
                              subscriptionTotal: order.totalAmount
                          } as SubscriptionOrder)
                        : ({
                              orderId: order.id,
                              purchaseDate: moment(order.purchaseDate),
                              orderProducts: subscriptionProducts,
                              orderTotal: orderTotal.orderTotal,
                              orderDiscount: orderTotal.orderDiscount,
                              subscriptionTotal: 0,
                              onlineOrderStatus: order.onlineOrderStatus
                          } as SubscriptionOrder);

                return [...products, subscriptionOrder];
            }, []) ?? [];

        let subscriptionPayments: SubscriptionOrder[] = [];
        if (userSubscription.subscriptionPayments?.length) {
            /** Create subscription order from subscription payments */
            subscriptionPayments = userSubscription.subscriptionPayments?.reduce(
                (products: SubscriptionOrder[], order: Order) => {
                    const subscriptionOrder = {
                        orderId: order.id,
                        purchaseDate: moment(order.purchaseDate),
                        subscriptionPayments: order,
                        orderTotal: 0,
                        orderDiscount: 0,
                        subscriptionTotal: order.totalAmount
                    } as SubscriptionOrder;
                    return [...products, subscriptionOrder];
                },
                []
            );
        }
        setSubscriptionOrders(subscriptionProducts.concat(subscriptionPayments).sort(sortByDates));
    };

    useEffect(() => {
        if (orders?.getOrdersByOrderIds && !loading) {
            getSubscriptionOrderProducts();
        }
    }, [orders]);

    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center">
                    {translate("orderHistory")}
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    {loading && (
                        <Flex direction="column">
                            {[...Array(7)].map((_, index: number) => {
                                return <Skeleton width="100%" height="5rem" mt={2} key={index + 1} />;
                            })}
                        </Flex>
                    )}
                    {subscriptionOrders && (
                        <FadeInBox width="100%" minH="6rem" p={3}>
                            <Pagination items={subscriptionOrders} numberPerPage={7}>
                                <PageButtons
                                    showMaxPage={5}
                                    wrapperProps={{
                                        marginTop: 0,
                                        marginBottom: "0.8rem",
                                        boxShadow: "none",
                                        top: "10px"
                                    }}
                                />
                                <NewDivider color="gray.400" />
                                <CurrentPage
                                    width="100%"
                                    wrapperProps={{ justifyContent: "flex-start" }}
                                    renderItem={(order: SubscriptionOrder, _: number) => {
                                        return (
                                            <React.Fragment key={order.orderId}>
                                                <OrderTile subscriptionOrder={order} />
                                                <NewDivider color="gray.400" />
                                            </React.Fragment>
                                        );
                                    }}
                                />
                            </Pagination>
                        </FadeInBox>
                    )}
                </DrawerBody>
                <DrawerActions marginTop="auto">
                    <SummaryTotals
                        subscriptionOrders={subscriptionOrders ?? []}
                        isGroupSubscription={isGroupSubscription}
                    />
                </DrawerActions>
            </DrawerContent>
        </Drawer>
    );
};
