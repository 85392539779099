import React from "react";
import { useQuery } from "react-apollo";

import { Header, Box, Spinner, Flex } from "Atoms";
import { GET_SYSTEM_VARIABLES } from "GraphQLQueries";
import { envConstants } from "Constants";
import { useAccordionItemContext } from "Organisms";

export const SystemVariables = () => {
    const { isExpanded } = useAccordionItemContext();

    const { data: getSystemVariables, loading: loadingSystemVariables } = useQuery(GET_SYSTEM_VARIABLES, {
        fetchPolicy: "network-only",
        skip: !isExpanded
    });

    if (loadingSystemVariables || !getSystemVariables) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        <>
            <Header as="h5" size="sm" fontWeight="normal">
                Miljövariabler som mothership använder sig av.
            </Header>
            <Box as="code" background="#eee" border="1px solid #999" display="block" p={8} whiteSpace="pre-wrap">
                {getSystemVariables.getEnvVariables}
                <br />
                NetsCloudSocketUrl: {envConstants.NETS_CLOUD_SOCKET_URL}
                <br />
                NetsCloudRestUrl: {envConstants.NETS_CLOUD_REST_URL}
            </Box>
        </>
    );
};
