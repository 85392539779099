import React, { useEffect } from "react";
import * as yup from "yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useMothershipLazyQuery, useMothershipMutation } from "Hooks";
import { GET_CUSTOMER_OPT_OUT_BY_EMAIL } from "GraphQLQueries";
import { Box, Button, Fade, RHFormInput, RHFormLabelAndValue, RHSelectInput } from "Atoms";
import { CustomerOptOutEmails, OptOutEmail } from "Types";
import { Languagekey, useLanguage } from "Providers";
import { ADD_OR_UPDATE_CUSTOMER_OPT_OUT_EMAIL } from "GraphQLMutations";
import { showToastError, showToastSuccess } from "Utils";

enum SUBMIT_TYPE {
    SEARCH = "SEARCH",
    SAVE = "SAVE"
}

type OptOutSearchAndAdd = CustomerOptOutEmails & { emailToSearch?: string; submitType?: SUBMIT_TYPE };

/** No point placing these in a separate file */
namespace OptOutQueryOrMutation {
    export type GetCustomerByEmail = {
        getCustomerOptOutByEmail: OptOutSearchAndAdd;
    };
    export type CreateOrSaveOptOut = {
        createOrSaveCustomerOptOut: boolean;
    };
}

type FormPropDataType = string | Date | boolean | OptOutEmail[];

const formPropNames: Array<keyof OptOutSearchAndAdd> = [
    "email",
    "id",
    "createdAt",
    "deleted",
    "optOutFrom",
    "updatedAt"
];

export const CustomerOptOutEmail: React.FC<{}> = ({}) => {
    const { translate } = useLanguage();

    const [getCustomerOptOutByEmail, { data, loading }] =
        useMothershipLazyQuery<OptOutQueryOrMutation.GetCustomerByEmail>(GET_CUSTOMER_OPT_OUT_BY_EMAIL);

    const [saveOrUpdateCustomerOptOuts] = useMothershipMutation<OptOutQueryOrMutation.CreateOrSaveOptOut>(
        ADD_OR_UPDATE_CUSTOMER_OPT_OUT_EMAIL
    );

    const {
        control,
        handleSubmit,
        setValue,
        formState: { isSubmitting, isValid }
    } = useForm<OptOutSearchAndAdd>({
        mode: "onBlur",
        resolver: yupResolver(
            yup.object().shape({
                emailToSearch: yup.string().email(translate("formErrorValidEmail")).required(translate("mustFillIn"))
            })
        )
    });

    /** Setting values after search need a watch here */
    const id = useWatch({ control, name: "id" });
    const submitTypeValue = useWatch({ control, name: "submitType" });
    const optOutFrom = useWatch({ control, name: "optOutFrom" });

    const optOutOptions = Object.keys(OptOutEmail)
        .filter(value => value != OptOutEmail.CAMPAIGNS)
        .map((value: string) => ({
            label: translate(value as Languagekey),
            value
        }));

    const searchForCustomerEmail = (email: string) => {
        getCustomerOptOutByEmail({
            variables: {
                email: email.trim()
            }
        });
    };

    const onSearchOrSaveSubmit: SubmitHandler<OptOutSearchAndAdd> = async (data: OptOutSearchAndAdd) => {
        const { submitType } = data;

        if (submitType === SUBMIT_TYPE.SEARCH) {
            searchForCustomerEmail(data.emailToSearch!);
            setValue("email", data.emailToSearch!.trim());
        } else {
            const { emailToSearch, submitType, ...saveValues } = data;
            const response = await saveOrUpdateCustomerOptOuts({
                variables: {
                    customerOptOuts: saveValues
                }
            });

            const hasBeenSaved = response.data?.createOrSaveCustomerOptOut === true;

            if (hasBeenSaved) {
                showToastSuccess(`${saveValues.email} - ${translate("hasBeenUpdated")}`);
                searchForCustomerEmail(saveValues.email);
            } else {
                showToastError(translate("somethingWentWrong"));
            }
        }
    };

    /** Set form values with prop + value (FormProp - Date | string etc..) (After searching for the email) */
    const setFormValues = (customerOptOut?: OptOutSearchAndAdd) => {
        if (!!customerOptOut) {
            /** Email opt-out has been found and set all values in the form */
            Object.entries(customerOptOut).forEach(([key, value]: [string, FormPropDataType]) => {
                if (key !== "__typename") {
                    setValue(key as keyof OptOutSearchAndAdd, value);
                }
            });
        } else {
            /** Email opt-out has not been found so set all value except email as undefined */
            formPropNames.forEach((name: keyof OptOutSearchAndAdd) => {
                if (name !== "emailToSearch" && name !== "email") {
                    setValue(name, undefined);
                }
            });
        }
    };

    useEffect(() => {
        setFormValues(data?.getCustomerOptOutByEmail);
    }, [data?.getCustomerOptOutByEmail]);

    /** Data check + if customer has opt-outs */
    const customerOptOut = data?.getCustomerOptOutByEmail;
    const customerHasOptOuts = !!customerOptOut?.email;

    /** For showing more form inputs after the search - in case support want to add the email to the opt-out collection */
    const hasSearchedForEmail = data && !loading;
    const disableSaveButton = !id && !optOutFrom?.length;

    /** Submit type */
    const isSearchSubmit = submitTypeValue === SUBMIT_TYPE.SEARCH;
    const isSaveSubmit = submitTypeValue === SUBMIT_TYPE.SAVE;

    return (
        <>
            <form onSubmit={handleSubmit(onSearchOrSaveSubmit)}>
                <RHFormInput formLabel={translate("emailAlt")} name="emailToSearch" control={control} />

                <Button
                    type="submit"
                    themeColor="blue"
                    isLoading={isSubmitting && isSearchSubmit}
                    isDisabled={!isValid}
                    fullWidth
                    onClick={() => {
                        setValue("submitType", SUBMIT_TYPE.SEARCH);
                    }}
                >
                    {translate("search")}
                </Button>

                {hasSearchedForEmail ? (
                    <Fade
                        in={true}
                        transition={{
                            enter: {
                                duration: 0.5
                            },
                            exit: {
                                duration: 0.5
                            }
                        }}
                    >
                        <Box backgroundColor="white" boxShadow="sm" p={4} mt={4}>
                            <RHFormLabelAndValue
                                name="email"
                                languageKey="emailAlt"
                                control={control}
                                wrapperProps={{ mt: 2 }}
                            />
                            <RHFormLabelAndValue name="createdAt" languageKey="date" control={control} isDate />
                            <RHFormLabelAndValue name="updatedAt" languageKey="lastModified" control={control} isDate />
                            <RHSelectInput
                                control={control}
                                name="optOutFrom"
                                formLabel={translate("unregisteredFrom")}
                                options={optOutOptions}
                                isClearable
                                isMulti
                            />

                            <Button
                                type="submit"
                                themeColor="green"
                                mt={2}
                                height="3rem"
                                fullWidth
                                isLoading={isSubmitting && isSaveSubmit}
                                isDisabled={disableSaveButton}
                                onClick={() => {
                                    setValue("submitType", SUBMIT_TYPE.SAVE);
                                }}
                            >
                                {customerHasOptOuts ? translate("save") : translate("add")}
                            </Button>
                        </Box>
                    </Fade>
                ) : null}
            </form>
        </>
    );
};
