import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Formik, Field, Form } from "formik-next";
import * as Yup from "yup";
import { MdDone } from "@react-icons/all-files/md/MdDone";

import { Box, Button, Flex, Text, Grid, Link, Spinner } from "Atoms";
import { GET_SUPER_ADMIN_COMPANIES, GET_COMPANY_MENU_NAMES } from "GraphQLQueries";
import { COPY_ITEMS_FROM_COMPANY, COPY_IMAGES_FROM_COMPANY } from "GraphQLMutations";
import { LoadingMessage, confirmNotification, successNotification, errorNotification } from "TempUtils";
import { useLanguage } from "Providers";
import { CheckBoxInput, SelectInput } from "Molecules";
import { useMothershipLazyQuery, useMothershipMutation } from "Hooks";
import { CheckBoxGroupNext } from "../../form-components/CustomInput";
import { useAccordionItemContext } from "Organisms";

export const CopyCompany = () => {
    const { isExpanded } = useAccordionItemContext();

    const [isCopying, setIsCopying] = useState(false);
    const [savedData, setSavedData] = useState({
        savedImages: 0,
        savedItems: 0
    });

    const { data, loading } = useQuery(GET_SUPER_ADMIN_COMPANIES, { fetchPolicy: "network-only", skip: !isExpanded });
    const [getCompanyMenus, { data: getCompanyMenusNames }] = useMothershipLazyQuery(GET_COMPANY_MENU_NAMES, {
        fetchPolicy: "network-only"
    });

    const [copyItemsFromCompany] = useMothershipMutation(COPY_ITEMS_FROM_COMPANY);
    const [copyImagesFromCompany] = useMothershipMutation(COPY_IMAGES_FROM_COMPANY);

    const { translate, translateWithArgument, translateWithMultipleArguments } = useLanguage();

    if (loading || !data) return <LoadingMessage />;

    const validationSchema = Yup.object().shape({
        companyToCopyFrom: Yup.string().required(translate("requiredError")),
        companyToCopyTo: Yup.string().required(translate("requiredError"))
    });

    const companyList = data.getSuperAdminCompanyList
        .map((companySettings: any) => ({
            label: companySettings.company.name,
            value: companySettings.company.id
        }))
        .sort((a: any, b: any) => a.label.localeCompare(b.label));

    const getMenus = (value: any) => {
        getCompanyMenus({
            variables: { companyId: value, includeDisabled: true, includeDeleted: false }
        });
    };

    const handleSubmit = async (values: any) => {
        setIsCopying(true);
        const companyNameToCopyFrom = companyList.find((c: any) => c.value === values.companyToCopyFrom).label;
        const companyNameToCopyTo = companyList.find((c: any) => c.value === values.companyToCopyTo).label;
        const numberOfMenu = values.menus.length;

        const res = await confirmNotification(
            translateWithMultipleArguments("copyFromTo", [companyNameToCopyFrom, companyNameToCopyTo]),
            "",
            translate("yesDoIt")
        );
        if (res) {
            if (values.shouldCopyImages) {
                const savedImagesResp: any = await copyImagesFromCompany({
                    variables: {
                        fromCompanyId: values.companyToCopyFrom,
                        toCompanyId: values.companyToCopyTo
                    }
                });
                savedImagesResp &&
                    setSavedData({ ...savedData, savedImages: savedImagesResp.data.copyImagesFromCompany });
            }

            const savedItemsResp: any = await copyItemsFromCompany({
                variables: {
                    fromCompanyId: values.companyToCopyFrom,
                    toCompanyId: values.companyToCopyTo,
                    menuIds: values.menus,
                    shouldCopyAddons: values.shouldCopyAddons
                }
            });

            if (savedItemsResp) {
                setSavedData(prev => ({ ...prev, savedItems: savedItemsResp.data.copyItemsFromCompany }));
            }
        }
    };

    const shopHasMenus = getCompanyMenusNames?.getMenus.length > 0;

    return (
        <Box minHeight="20rem" position="relative">
            <Formik
                initialValues={{
                    companyToCopyFrom: "",
                    companyToCopyTo: "",
                    shouldCopyImages: true,
                    shouldCopyAddons: true,
                    shouldCopyProducts: true,
                    menus: []
                }}
                onSubmit={values => {
                    handleSubmit(values);
                }}
                validationSchema={validationSchema}
            >
                {({ values, setValues, isValid }) => {
                    const menuText =
                        values.companyToCopyFrom && !shopHasMenus
                            ? translate("noMenuToCopy")
                            : translate("selectCompanyToCopyFrom");

                    return (
                        <Form>
                            {isCopying && (
                                <Box
                                    w="100%"
                                    h="100%"
                                    bg="whiteAlpha.800"
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    zIndex={100}
                                >
                                    <Flex direction={"column"} justifyContent="center" alignItems={"center"}>
                                        {values.shouldCopyImages && (
                                            <>
                                                <Flex h="auto" alignItems={"center"}>
                                                    <Text fontSize={"1.3rem"} mb={0}>
                                                        {translate("saveImages")}
                                                    </Text>
                                                    {savedData.savedImages > 0 && (
                                                        <>
                                                            <Box as={MdDone} color="green.600" mr={2} size="1.5rem" />
                                                            <Text fontSize={"1.3rem"}>
                                                                {translateWithArgument(
                                                                    "savedImages",
                                                                    savedData.savedImages
                                                                )}
                                                            </Text>
                                                        </>
                                                    )}
                                                </Flex>
                                                <Text color="gray.600">{translate("toCopyAllImagesCanTakeTime")}</Text>
                                            </>
                                        )}

                                        <Flex h="auto" alignItems={"center"}>
                                            <Text fontSize={"1.3rem"} mb={0}>
                                                {translate("saveItems")}
                                            </Text>
                                            {!!savedData.savedItems && (
                                                <>
                                                    <Box as={MdDone} color="green.600" mr={2} size="1.5rem" />
                                                    <Text fontSize={"1.3rem"}>
                                                        {" "}
                                                        {translateWithArgument("totalSavedItems", savedData.savedItems)}
                                                    </Text>
                                                </>
                                            )}
                                        </Flex>

                                        {savedData.savedItems == 0 ? (
                                            <Spinner />
                                        ) : (
                                            <Button
                                                themeColor="green"
                                                mt={8}
                                                onClick={() => {
                                                    setIsCopying(false);
                                                }}
                                            >
                                                {translate("done")}
                                            </Button>
                                        )}
                                    </Flex>
                                </Box>
                            )}
                            <Grid templateColumns="1fr 1fr" gap="2rem">
                                <Field
                                    formLabel={translate("copyFrom")}
                                    placeholder={translate("selectCompanyHelper")}
                                    name="companyToCopyFrom"
                                    onFieldChange={(value: string) => {
                                        getMenus(value);
                                    }}
                                    options={companyList}
                                    as={SelectInput}
                                />
                                <Field
                                    formLabel={translate("copyTo")}
                                    placeholder={translate("selectCompanyHelper")}
                                    name="companyToCopyTo"
                                    options={companyList}
                                    as={SelectInput}
                                />
                            </Grid>
                            <Box minHeight="10rem" p={4} rounded="lg" bg="whiteAlpha.800" border="1px solid #EDF2F7">
                                <Box as="h3">Ska kopieras:</Box>
                                <Flex direction={"column"} alignItems="flex-start" pl={4}>
                                    <Field
                                        name="shouldCopyProducts"
                                        formLabel={translate("copyAllProducts")}
                                        isDisabled
                                        textAlign="left"
                                        formLabelProps={{ marginLeft: "1rem", paddingBottom: 0 }}
                                        as={CheckBoxInput}
                                    />

                                    <Field
                                        name="shouldCopyAddons"
                                        formLabel={translate("copyAddons")}
                                        textAlign="left"
                                        formLabelProps={{ marginLeft: "1rem", paddingBottom: 0 }}
                                        as={CheckBoxInput}
                                    />
                                    <Field
                                        name="shouldCopyImages"
                                        formLabel={translate("copyImages")}
                                        textAlign="left"
                                        formLabelProps={{ marginLeft: "1rem", paddingBottom: 0 }}
                                        as={CheckBoxInput}
                                    />
                                </Flex>

                                <Flex>
                                    <Box as="h4" pr={4}>
                                        {translate("menusToCopy")}
                                    </Box>
                                    {shopHasMenus && (
                                        <>
                                            <Link
                                                as="span"
                                                color={"blue.600"}
                                                onClick={() => {
                                                    const allMenusIds = getCompanyMenusNames?.getMenus.map(
                                                        (menu: any) => menu.id
                                                    );
                                                    setValues({ ...values, menus: allMenusIds });
                                                }}
                                            >
                                                {translate("all")}
                                            </Link>
                                            <Box px={2}>|</Box>
                                            <Link
                                                as="span"
                                                color={"blue.600"}
                                                onClick={() => {
                                                    setValues({ ...values, menus: [] });
                                                }}
                                            >
                                                {translate("noneAlt")}
                                            </Link>
                                        </>
                                    )}
                                </Flex>
                                {getCompanyMenusNames?.getMenus.length ? (
                                    <Box>
                                        <CheckBoxGroupNext
                                            name={`menus`}
                                            checkboxOptions={getCompanyMenusNames?.getMenus.map((menu: any) => ({
                                                id: menu.id,
                                                key: menu.id,
                                                name: menu.name
                                            }))}
                                            selectedValues={values.menus}
                                        />
                                    </Box>
                                ) : (
                                    <Text pt={4} color="gray.600">
                                        {menuText}
                                    </Text>
                                )}
                            </Box>
                            <Button
                                fullWidth
                                mt={6}
                                isDisabled={values.companyToCopyFrom === values.companyToCopyTo && isValid}
                                type="submit"
                                themeColor="green"
                            >
                                {translate("copy")}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};
